import BottomImages from "../components/BottomImages";
import HeroSection from "../components/HeroSection";
import EventTimeline from "../components/EventTimeline";
import CardContainer from "../components/CardContainer";
import SquareContainer from "../components/timer/SquareContainer";
import WeddingStory from "../components/WeddingStory";
import ContactUs from "../components/ContactUs";
import Transportation from "../components/Transportation";
import ThankYou from "../components/ThankYou";
import Registry from "../components/Registry";

const Header = () => {
  return (
    <>
      <HeroSection />
      <div id="data-section" className="flex justify-center items-center pt-5">
        <img
          src="https://dei7w7b9qtqsy.cloudfront.net/images/image2-transformed.svg"
          alt="Hero Section"
          className="w-full max-w-full h-auto"
        />
      </div>
      <EventTimeline />
      <WeddingStory />
      <SquareContainer />
      <CardContainer />
      <Transportation />
      <Registry />
      <ContactUs />
      <ThankYou />
      <BottomImages />
    </>
  );
};

export default Header;
