import React, { useState } from "react";
import { Typography } from "@mui/material";
import Location from "../icons/Location";
import StarIcon from "@mui/icons-material/Star";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import "./../../index.css";
import { createClient } from "@supabase/supabase-js";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:4040";

if (!supabaseUrl || !supabaseKey) {
  throw new Error(
    "Supabase URL and key must be provided in the environment variables."
  );
}

const supabase = createClient(supabaseUrl, supabaseKey);

interface MarkerData {
  id: number;
  lat: number;
  lng: number;
  title: string;
  description: string;
  imageUrl: string;
  rating: number;
  ratingCount: number;
  attendees: string;
}

interface FirstModalProps {
  marker: MarkerData;
  isMobile: boolean;
  open: boolean;
  handleClose: () => void;
  handleOpened: () => void;
  locationName: string;
  directions: string;
}

const FirstModal: React.FC<FirstModalProps> = ({
  marker,
  isMobile,
  open,
  handleClose,
  handleOpened,
  locationName,
  directions,
}) => {
  const { t } = useTranslation();

  // FIXME: remove if not needed
  // signin to Google Calendar to add the Event in the user Calendar
  // const googleSignIn = async () => {
  //   const { data, error } = await supabase.auth.signInWithOAuth({
  //     provider: "google",
  //     options: {
  //       scopes: "https://www.googleapis.com/auth/calendar",
  //       skipBrowserRedirect: true,
  //       redirectTo: `${baseUrl}/addtocalendar`,
  //     },
  //   });

  //   if (!error && data) {
  //     const authUrl = data.url;
  //     window.open(authUrl, "_blank", "width=500,height=600");
  //   } else {
  //     alert("Error when logging in");
  //     console.error(error);
  //   }
  // };
  const handleAddToCalendar = () => {
    //FIXME: add time and date
    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&text=${marker.title}&dates=20211031T120000/20211031T140000&details=${marker.description}&location=${locationName}`,
      "_blank"
    );
  };

  const [infoPopUp, setInfoPopUp] = useState(false);

  return (
    <div className="flex flex-col md:max-w-[500px] w-full md:pl-1 md:pt-1 z-50 relative">
      <img
        src={marker.imageUrl}
        alt={marker.title}
        className="max-w-full h-[151px] object-cover rounded-lg "
      />
      <h6 className="text-orange font-semibold font-bitter pt-2.5 text-xs md:text-sm">
        {marker.title}
      </h6>
      <h6 className="font-bitter font-medium text-xs md:text-lg my-2.5">
        {marker.description}
      </h6>

      <Typography variant="body1">
        {isMobile ? (
          <div className="flex flex-row text-sm md:text-base">
            <StarIcon sx={{ color: "#ffc93e", mr: "3px" }} />{" "}
            <div className="mr-3">{marker.rating}</div>
            <div>
              ({marker.ratingCount}+)
              <FiberManualRecordIcon
                sx={{ fontSize: "5px", mx: "5px", mt: "10px" }}
              />
              {marker.attendees}
              <InfoIcon
                sx={{ color: "#b2b2b2", fontsize: "16px", mx: "5px" }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row text-xs md:text-base">
            <StarIcon sx={{ color: "#ffc93e", mr: "5px" }} />{" "}
            <div className="mr-2 text-xs md:text-base">{marker.rating}</div>(
            {marker.ratingCount}+)
            <FiberManualRecordIcon
              sx={{ fontSize: "5px", mx: "5px", mt: "10px" }}
            />
            {marker.attendees}
            <div className="relative">
              <div
                onMouseEnter={() => setInfoPopUp(true)}
                onMouseLeave={() => setInfoPopUp(false)}
              >
                <InfoIcon
                  sx={{ color: "#b2b2b2", fontsize: "16px", ml: "5px" }}
                />
              </div>
              <div
                className={`absolute min-w-[200px] bottom-6 rounded-xl right-0 p-5 bg-green text-white font-bold ${
                  infoPopUp ? "block" : "hidden"
                }`}
              >
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eius
                sequi quod fugit laudantium qui. Sit quo perferendis, at magnam,
                debitis pariatur iure consequuntur nobis corrupti aut atque
              </div>
            </div>
          </div>
        )}
      </Typography>
      <Typography
        variant="body1"
        style={{
          display: "flex",
          marginBottom: "12px",
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <Location />{" "}
        <a
          className="ml-2 text-sm md:text-base"
          style={{ fontFamily: "EB Garamond", fontWeight: "400" }}
          href={directions}
          target="_blank"
          rel="noreferrer"
        >
          {locationName}
        </a>
      </Typography>
      <Typography
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={handleAddToCalendar}
          className="bg-white border border-[#E4E6EA] rounded-lg text-black w-[48%] h-[6vh] cursor-pointer text-xs md:text-sm"
        >
          {isMobile ? "Add" : t("Add to calendar")}
        </button>
        <button
          onClick={handleOpened}
          className="bg-darkGray rounded-lg text-white w-[48%] h-[6vh] cursor-pointer text-xs md:text-sm"
        >
          {isMobile ? "View" : t("View Event Timeline")}
        </button>
      </Typography>
    </div>
  );
};

export default FirstModal;
