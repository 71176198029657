import { useState, useEffect } from "react";
import axios from "axios";
import { Home, AddToCalender } from "./pages";
import { TravelAndStayProvider } from "./Context/TravelAndStay";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [message, setMessage] = useState<string>("");
  const API_ENDPOINT =
    process.env.REACT_APP_API_URL || "http://localhost:4040/";

  useEffect(() => {
    axios
      .get(API_ENDPOINT)
      .then((response) => {
        setMessage(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-stone-50 overflow-hidden font-bitter">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/addtocalendar" element={<AddToCalender />} />
        </Routes>
      </Router>
    </div>
  );
}

const AppWithProvider = () => (
  <TravelAndStayProvider>
    <App />
  </TravelAndStayProvider>
);

export default AppWithProvider;
