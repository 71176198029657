import { TimelineItemProps } from "./EventTimeline";

const TimelineItem: React.FC<TimelineItemProps> = ({
  image,
  title,
  subheading,
  name,
  description,
  icons,
}) => {
  return (
    <div className="flex items-center flex-col md:flex-row px-3 lg:px-0 md:space-x-[80px] font-eb">
      <div className="max-w-[330px] max-h-[346px] flex-shrink-0">
        <img src={image} alt="Event" />
      </div>

      <div className="md:max-w-[379px] mt-3 md:mt-0">
        <h2 className="font-bold text-[40px] mb-4 text-dark">{title}</h2>
        <h3 className="text-2xl text-green mb-4 font-bold">{name}</h3>
        <h4 className="text-xl text-black mb-4 mt-2 font-bold">{subheading}</h4>
        <p className="text-xl text-dark">{description}</p>

        <div className="mt-[36px] flex flex-col space-y-[12px] text-left max-md:items-center">
          {icons.map((icon) => (
            <div className="flex items-center space-x-[10px]" key={icon.label}>
              <div>{icon.icon}</div>

              <p className="text-base md:text-xl leading-30 tracking-20 font-medium">
                {icon.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
