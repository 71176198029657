import { Box, Modal, Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomPaper from "./CustomPaper";
import { useTranslation } from "react-i18next";
import BreakFast2 from "../icons/BreakFast2";
import Shirt2 from "../icons/Shirt2";
interface WeddingScheduleModalProps {
  open: boolean;
  handleClose: () => void;
}

const DataModal = ({ open, handleClose }: WeddingScheduleModalProps) => {
  const { t } = useTranslation();
  const style = {
    width: "100%",
    maxWidth: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "100vh",
    overflow: "auto",
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          justifyItems="space-center"
          flexDirection="row-reverse"
          width="100%"
          marginBottom="24px"
        >
          <IconButton
            onClick={handleClose}
            sx={{
              width: 44,
              height: 44,
              border: "1px solid  #E4E6EA",
              borderRadius: "20%",
              padding: "1rem",
            }}
          >
            <CloseIcon />
          </IconButton>
          <h3 className="font-bold leading-[37px] font-eb text-2xl md:text-[28px]">
            {t("Event Timeline")}
          </h3>
        </Box>

        <Box sx={{ pb: "1.5rem" }}>
          <div className="flex flex-col md:gap-6 md:flex-row md:items-center">
            <Grid item>
              <img
                src="https://dei7w7b9qtqsy.cloudfront.net/images/pensativo_squared.svg"
                alt="Mehndi & Sangeet Ceremony"
                style={{
                  width: 220,
                  height: 220,
                  padding: "8px",
                  borderRadius: "5px",
                  boxShadow: "0 0 10px rgba(50, 50, 50, 0.1)",
                }}
              />
            </Grid>

            <Grid item xs>
              <h4 className="font-eb font-bold text-xl md:text-2xl text-dark">
                {t("Day One")}
              </h4>
              <h5 className="text-green font-eb font-bold my-2">
                {t("Haldi, Mehndi and Sangeet")}
              </h5>
              <p className="text-dark font-eb">
                {t("Join us as we kickoff our wedding celebrations with Jakli")}
              </p>
              <div className="flex flex-col md:flex-row md:items-center mt-[18px] gap-3">
                <div className="flex items-center gap-2">
                  <BreakFast2 />
                  <Typography sx={{ color: "#586959", fontSize: 12 }}>
                    {t("Dinner Provided")}
                  </Typography>
                </div>

                <div className="flex items-center gap-2">
                  <Shirt2 />
                  <Typography sx={{ color: "#586959", fontSize: 12 }}>
                    {t("Dress Code: Indian or formal attire")}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
        </Box>
        <Box sx={{ pb: "4rem" }}>
          <div className="flex flex-col md:gap-6 md:flex-row md:items-center">
            <Grid item>
              <img
                src="https://dei7w7b9qtqsy.cloudfront.net/images/santa_clara_squared.svg"
                alt="Ceremnoy & Reception"
                style={{
                  width: 220,
                  height: 220,
                  borderRadius: "5px",
                  padding: "8px",
                  boxShadow: "0 0 10px rgba(50, 50, 50, 0.1)",
                }}
              />
            </Grid>

            <Grid item xs>
              <h4 className="font-eb font-bold text-xl md:text-2xl text-dark">
                {t("Day Two")}
              </h4>
              <h5 className="text-green font-eb font-bold my-2">
                {t("Ceremony & Reception")}
              </h5>
              <p className="text-dark font-eb">
                {t(
                  "The main commony and reception are the heart of our wolding"
                )}
              </p>
              <div className="flex flex-col md:flex-row md:items-center mt-4  gap-3">
                <div className="flex items-center gap-2">
                  <BreakFast2 />
                  <Typography sx={{ color: "#586959", fontSize: 12 }}>
                    {t("Breakfast, Lunch, and Dinner Provided")}
                  </Typography>
                </div>

                <div className="flex items-center gap-2">
                  <Shirt2 />
                  <Typography sx={{ color: "#586959", fontSize: 12 }}>
                    {t("Dress Code: Indian or formal attire")}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            sx={{ pt: "2rem" }}
          >
            <CustomPaper
              duration="Duration: 2 Hours"
              title={t("Meeting with Bridesmaids and Groomsmen")}
              time="09:00 AM"
            />
            <CustomPaper
              duration="Duration: 2 Hours"
              title={t("Ceremony at Convento Santa Clara")}
              time="01:30 PM"
            />
            <CustomPaper
              duration="Duration: 2 Hours"
              title={t("Reception at Casa Santo Domingo")}
              time="01:30 PM"
            />
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default DataModal;
