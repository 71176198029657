import React, { createContext, ReactNode, useContext, useState } from 'react';

interface TravelAndStayContextProps {
  category: string;
  toggleCategory: (value: string) => void;
}

const TravelAndStayContext = createContext<TravelAndStayContextProps | undefined>(undefined);

interface TravelAndStayProviderProps {
  children: ReactNode;
}

const TravelAndStayProvider: React.FC<TravelAndStayProviderProps> = ({ children }) => {
    //Global state
  const [category, setCategory] = useState<string>("lodging");

  const toggleCategory = (value: string) => {
    setCategory(value);
  };

  return (
    <TravelAndStayContext.Provider value={{ category, toggleCategory }}>
      {children}
    </TravelAndStayContext.Provider>
  );
};


// Error Handling
const useTravelAndStay = (): TravelAndStayContextProps => {
  const context = useContext(TravelAndStayContext);
  if (!context) {
    console.log('useTravelAndStay must be used within a TravelAndStayProvider');
    throw new Error('useTravelAndStay must be used within a TravelAndStayProvider');
  }
  return context;
};

export { TravelAndStayProvider, useTravelAndStay };
