import React from "react";

const Top = () => {
  return (
    <svg
      width="156"
      height="26"
      viewBox="0 0 156 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_51_2163)">
        <path
          d="M48.5 19C69.7 12.5 78 0 78 0V26H0C0 26 21.8 26.6 48.5 19ZM107.5 19C86.3 12.5 78 0 78 0V26H156C156 26 134.2 26.6 107.5 19Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_2163">
          <rect width="156" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Top;
