import React from "react";

const Train = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M13.1503 8.66016C12.0895 8.66016 11.072 9.08158 10.3219 9.83173L10.0793 10.0744C9.2982 10.8554 8.03187 10.8554 7.25083 10.0744C6.46978 9.29332 6.46978 8.02699 7.25083 7.24594L7.49347 7.0033C8.99376 5.50301 11.0286 4.66016 13.1503 4.66016H36.1798C38.3015 4.66016 40.3363 5.50301 41.8366 7.0033L42.0793 7.24594C42.8603 8.02699 42.8603 9.29332 42.0793 10.0744C41.2982 10.8554 40.0319 10.8554 39.2508 10.0744L39.0082 9.83173C38.258 9.08158 37.2406 8.66016 36.1798 8.66016H26.665V12.6602H22.665V8.66016H13.1503Z"
          fill="#BF7F53"
        />
        <path
          d="M18.665 16.6602C17.5605 16.6602 16.665 17.5556 16.665 18.6602V24.6602H32.665V18.6602C32.665 17.5556 31.7696 16.6602 30.665 16.6602H18.665Z"
          fill="#BF7F53"
        />
        <path
          d="M13.319 37.3841C13.2955 37.338 13.2729 37.2918 13.2512 37.2455L9.25083 41.2459C8.46978 42.027 8.46978 43.2933 9.25083 44.0744C10.0319 44.8554 11.2982 44.8554 12.0793 44.0744L16.0797 40.074C16.0334 40.0523 15.9872 40.0297 15.9411 40.0062C14.8121 39.431 13.8942 38.5131 13.319 37.3841Z"
          fill="#BF7F53"
        />
        <path
          d="M33.2504 40.074C33.2967 40.0523 33.3429 40.0297 33.389 40.0062C34.518 39.431 35.4358 38.5131 36.0111 37.3841C36.0346 37.338 36.0571 37.2918 36.0789 37.2456L40.0793 41.2459C40.8603 42.027 40.8603 43.2933 40.0793 44.0744C39.2982 44.8554 38.0319 44.8554 37.2508 44.0744L33.2504 40.074Z"
          fill="#BF7F53"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.3421 12.6602H20.988C19.9337 12.6601 19.0241 12.6601 18.275 12.7213C17.4843 12.7859 16.6979 12.9285 15.9411 13.3141C14.8121 13.8894 13.8942 14.8072 13.319 15.9362C12.9334 16.693 12.7908 17.4794 12.7262 18.2701C12.665 19.0192 12.665 19.9287 12.665 20.983V32.3373C12.665 33.3915 12.665 34.3011 12.7262 35.0502C12.7908 35.8409 12.9334 36.6273 13.319 37.3841C13.8942 38.5131 14.8121 39.431 15.9411 40.0062C16.6979 40.3918 17.4843 40.5344 18.275 40.599C19.0241 40.6602 19.9336 40.6602 20.9879 40.6602H28.3421C29.3964 40.6602 30.306 40.6602 31.0551 40.599C31.8458 40.5344 32.6322 40.3918 33.389 40.0062C34.518 39.431 35.4358 38.5131 36.0111 37.3841C36.3967 36.6273 36.5393 35.8409 36.6039 35.0502C36.6651 34.3011 36.6651 33.3916 36.665 32.3373V20.9831C36.6651 19.9288 36.6651 19.0192 36.6039 18.2701C36.5393 17.4794 36.3967 16.693 36.0111 15.9362C35.4358 14.8072 34.518 13.8894 33.389 13.3141C32.6322 12.9285 31.8458 12.7859 31.0551 12.7213C30.306 12.6601 29.3964 12.6601 28.3421 12.6602ZM18.665 34.6602C19.7696 34.6602 20.665 33.7647 20.665 32.6602C20.665 31.5556 19.7696 30.6602 18.665 30.6602C17.5605 30.6602 16.665 31.5556 16.665 32.6602C16.665 33.7647 17.5605 34.6602 18.665 34.6602ZM32.665 32.6602C32.665 33.7647 31.7696 34.6602 30.665 34.6602C29.5605 34.6602 28.665 33.7647 28.665 32.6602C28.665 31.5556 29.5605 30.6602 30.665 30.6602C31.7696 30.6602 32.665 31.5556 32.665 32.6602ZM18.665 16.6602C17.5605 16.6602 16.665 17.5556 16.665 18.6602V24.6602H32.665V18.6602C32.665 17.5556 31.7696 16.6602 30.665 16.6602H18.665Z"
        fill="#BF7F53"
      />
    </svg>
  );
};

export default Train;
