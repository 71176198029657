import { Grid, Box, useMediaQuery } from "@mui/material";
import Square from "./Square";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const SquareContainer = () => {
  // Set initial states to 108 days, 8 hours, and 50 minutes
  const [days, setDays] = useState(108);
  const [hours, setHours] = useState(8);
  const [minutes, setMinutes] = useState(50);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  useEffect(() => {
    let interval = setInterval(() => {
      if (minutes === 0 && hours === 0 && days === 0) {
        clearInterval(interval);
      } else {
        if (minutes === 0) {
          setMinutes(59);
          if (hours === 0) {
            setHours(23);
            setDays(days - 1);
          } else {
            setHours(hours - 1);
          }
        } else {
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [days, hours, minutes]);
  const { t } = useTranslation();

  return (
    <div className="w-full max-w-full bg-[url(https://dei7w7b9qtqsy.cloudfront.net/images/timer-section-wrapper.svg)] bg-cover bg-center h-screen flex items-center justify-center">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ color: "#fffbf6" }}
      >
        <Grid item>
          <Square>
            <Box
              sx={
                isMobile
                  ? { fontSize: "47px", fontWeight: "bold", marginTop: "-15px" }
                  : { fontSize: "65px", fontWeight: "bold", marginTop: "-20px" }
              }
            >
              {days < 10 ? `0${days}` : days}
            </Box>
            <Box
              sx={
                isMobile
                  ? { fontSize: "1rem", fontWeight: "bold", marginTop: "-10px" }
                  : { fontSize: "25px", fontWeight: "bold", marginTop: "-20px" }
              }
            >
              {t("Days")}
            </Box>
          </Square>
        </Grid>

        <Grid item>
          <Square>
            <Box
              sx={
                isMobile
                  ? { fontSize: "47px", fontWeight: "bold", marginTop: "-15px" }
                  : { fontSize: "65px", fontWeight: "bold", marginTop: "-20px" }
              }
            >
              {hours < 10 ? `0${hours}` : hours}
            </Box>
            <Box
              sx={
                isMobile
                  ? { fontSize: "1rem", fontWeight: "bold", marginTop: "-10px" }
                  : { fontSize: "25px", fontWeight: "bold", marginTop: "-20px" }
              }
            >
              {t("Hours")}
            </Box>
          </Square>
        </Grid>

        <Grid item>
          <Square>
            <Box
              sx={
                isMobile
                  ? { fontSize: "47px", fontWeight: "bold", marginTop: "-15px" }
                  : { fontSize: "65px", fontWeight: "bold", marginTop: "-20px" }
              }
            >
              {minutes < 10 ? `0${minutes}` : minutes}
            </Box>
            <Box
              sx={
                isMobile
                  ? { fontSize: "1rem", fontWeight: "bold", marginTop: "-10px" }
                  : { fontSize: "25px", fontWeight: "bold", marginTop: "-20px" }
              }
            >
              {t("Minutes")}
            </Box>
          </Square>
        </Grid>
      </Grid>
    </div>
  );
};

export default SquareContainer;
