import i18next, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../translations/en.json";
import es from "../translations/es.json";
import hi from "../translations/hi.json";

i18next.use(initReactI18next).init<InitOptions>({
  resources: {
    en: { translation: en },
    es: { translation: es },
    hi: { translation: hi },
  },
  lng: "en",
});

export default i18next;
