import { Box, useMediaQuery } from "@mui/material";

export default function BottomImages() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }
          : {
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              width: "100%",
              gap: "0",
              mx: "auto",
            }
      }
    >
      <img className="w-full" src="/image/footer1.svg" alt="Love 1" />
    </Box>
  );
}
