/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import Plane from "./icons/Plane";
import Train from "./icons/Train";
import Car from "./icons/Car";

const Transportation = () => {
  const { t } = useTranslation();

  interface TransportationItemProps {
    title: string;
    subtitle: string;
    description: string;
    icon: React.ReactNode;
    link: string;
  }
  const TransportationItem: React.FC<TransportationItemProps> = ({
    title,
    subtitle,
    description,
    icon,
    link,
  }) => (
    <div className="text-center md:text-left">
      <div className="mx-auto max-w-md flex flex-col items-center md:items-start">
        <div className="mb-4">{icon}</div>
        <h5 className="text-[28px] pt-4">{title}</h5>
        <a
          href={link}
          target="_blank"
          className="font-bold text-sm pt-4 transition-colors hover:text-orange"
        >
          {subtitle}
        </a>
        <h5
          className="text-base mt-4"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );

  const trasportationData = [
    {
      title: t("By Car"),
      subtitle: t("Alamo, Hertz, Dollar, and Antigua Tours"),
      description: t("By Car Description"),
      icon: <Car />,
      link: "https://www.kayak.com/",
    },
    {
      title: t("Ride Share"),
      subtitle: t("Uber, Lyft, and More"),
      description: t("Ride Share Description"),
      icon: <Train />,
      link: "https://www.uber.com/global/en/r/cities/guatemala-gu-gt/",
    },
    {
      title: t("By Plane"),
      subtitle: t("Delta, American, and Avianca"),
      description: t("By Plane Description"),
      icon: <Plane />,
      link: "https://www.google.com/travel/flights",
    },
  ];

  return (
    <div className="px-3 lg:px-0 max-md:bg-white bg-white">
      <div className="pt-[75px] flex justify-center justify-items-center h-full">
        <h5 className="max-md:font-bold max-md:text-3xl font-bold text-5xl">
          {t("TRANSPORTATION")}
        </h5>
      </div>
      <div className="max-w-5xl mx-auto py-12">
        <div className="flex max-md:flex-col max-md:mx-4 flex-row gap-8 md:gap-6 justify-center justify-items-center">
          {trasportationData.map((item, index) => (
            <TransportationItem
              key={index}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
              icon={item.icon}
              link={item.link} // Pass link prop to component
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transportation;
