import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const Registry = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <Box className="bg-[#FEFBF9]">
      <Container maxWidth="xl" className="text-center py-12">
        <Typography variant="h5" fontSize="48px" fontWeight="700">
          {t("Registry")}
        </Typography>
        <Box className="me-5 ms-5">
          <Typography variant="h5" fontSize="20px" sx={{ pt: "24px" }}>
            {t("The most memorable gifts are those that come from the heart. No matter what you choose to give, know that your thoughtfulness means the world to us. Explore some great ideas through the links below, and remember, we will appreciate your presence more than anything else!")}
          </Typography>

          <div className="flex max-md:flex-col max-w-[740px] w-full mx-auto flex-row justify-center justify-items-center gap-6 mt-10">
            <div className="flex flex-col justify-center items-center w-full">
              <a href="https://www.zola.com/registry/ederandanmol" target="_blank" rel="noopener noreferrer">
                <Paper
                  elevation={3}
                  sx={{
                    background: "#ecceba",
                    width: isMobile ? "340px" : "420px", // Set explicit width
                    height: "176px",
                    display: "flex",
                    justifyContent: "center",
                    boxShadow: 0,
                  }}
                >
                  <div className="flex flex-col justify-center justify-items-center mb-3">
                    <img
                      className="mx-auto"
                      src="https://dei7w7b9qtqsy.cloudfront.net/images/gift1.svg"
                      alt="Gift 1"
                      width="80px"
                      height="80px"
                    />
                    <Box pt={1}>
                      <Typography
                        variant="h5"
                        fontSize={isMobile ? "25px" : "30px"}
                        fontWeight="bold"
                      >
                        {t("Zola Registry")}
                      </Typography>
                      <Typography
                        variant="h5"
                        fontSize={isMobile ? "13px" : "16px"}
                      >
                        {t("A Wedding Registry for Anmol & Eder")}
                      </Typography>
                    </Box>
                  </div>
                </Paper>
              </a>
            </div>
          </div>
        </Box>
      </Container>
    </Box>
  );
};

export default Registry;
