import CustomCard from "./CustomCard";
import { Container, useMediaQuery } from "@mui/material";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import Flight from "./icons/Flight";
import Lodging from "./icons/Lodging";
import Activities from "./icons/Activities";
import { useState, useEffect, useRef } from "react";
import { useTravelAndStay } from "../Context/TravelAndStay";
import ChevronLeft from "./icons/ChevronLeft";
import ChevronRight from "./icons/ChevronRight";

interface CardProps {
  category: string;
  imageSrc: string;
  imageAlt: string;
  title: string;
  address: string;
  rating: number;
  buttonText: string;
  urlLink?: string;
}

const CardContainer = () => {
  const { category, toggleCategory } = useTravelAndStay();

  const [selectedButton, setSelectedButton] = useState("lodging");
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const slider = useRef<Slider>(null);

  useEffect(() => {
    setSelectedButton(category);
  }, [category]);

  const settings: Settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tailwindStyle = {
    button2:
      "bg-green hover:bg-green border-none text-white flex items-center justify-center gap-3 font-medium text-18 cursor-pointer px-3 pr-5 py-[11px] rounded-full outline-none bg-opacity-60",
    button2Mobile:
      "bg-green hover:bg-green border-none text-white flex items-center justify-center gap-1 font-medium text-14 cursor-pointer pl-2 pr-3 py-1.5 rounded-full outline-none bg-opacity-60",
  };

  const { t } = useTranslation();

  const cards: CardProps[] = [
    {
      category: "lodging",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/casa_santo_domingo.png",
      imageAlt: "Casa Santo Domingo",
      title: "Casa Santo Domingo",
      address: "3a Calle Oriente No. 28 A, La Antigua Guatemala",
      rating: 4.7,
      buttonText: t("Book Now"),
      urlLink: "https://www.casasantodomingo.com.gt/",
    },
    {
      category: "lodging",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/pha-lobby_standard.jpg",
      imageAlt: "Porta Hotel Antigua",
      title: "Porta Hotel Antigua",
      address:
        "8va. Calle Poniente No.1 La Antigua Guatemala 03001, Sacatepequez - Guatemala, Central America",
      rating: 4.7,
      buttonText: t("Book Now"),
      urlLink: "https://bit.ly/anmolandederantigua2024-pha",
    },
    {
      category: "lodging",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/suite-virrey-01.jpg",
      imageAlt: "Camino Real Antigua",
      title: "Camino Real Antigua",
      address: "7A Calle Poniente 33B Antigua, Guatemala.",
      rating: 4.7,
      urlLink: "https://reservations.travelclick.com/106521?groupID=4185641",
      buttonText: t("Book Now"),
    },
    {
      category: "lodging",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/pensativo_hotel.png",
      imageAlt: "Pensativo House Hotel",
      title: "Pensativo House Hotel",
      address:
        "4a Avenida Sur No. 24 La Antigua Guatemala Sacatepéquez, 03001, Guatemala",
      rating: 4.8,
      urlLink: "https://www.pensativohousehotel.com/",
      buttonText: t("Book Now"),
    },
    {
      category: "lodging",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/goodhotel.jpg",
      imageAlt: "Good Hotel Antigua",
      title: "Good Hotel Antigua",
      address: "Calle del Hermano Pedro 12, Antigua 03001 Guatemala",
      rating: 4.7,
      urlLink: "https://goodhotel.co/",
      buttonText: t("Book Now"),
    },
    {
      category: "lodging",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/lospasos.jpeg",
      imageAlt: "Hotel Boutique Los Pasos",
      title: "Hotel Boutique Los Pasos",
      address: "In order to book a room, please contact the hotel directly via email: experiencias@hotellospasos.com",
      rating: 4.6,
      urlLink: "https://hotellospasos.com/",
      buttonText: t("Book Now"),
    },
    {
      category: "lodging",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/airbnblogo.jpeg",
      imageAlt: "Antigua Airbnb List",
      title: "Antigua Airbnb List",
      address: "Entire rental units in Antigua Guatemala, Guatemala *Recommended*",
      rating: 5.0,
      urlLink:
        "https://www.airbnb.com/wishlists/v/1460784153?s=67&unique_share_id=1b933e7f-c901-4ec7-80ff-8cc6717b085d",
      buttonText: t("Book Now"),
    },
    {
      category: "Flight",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/avianca.jpg",
      imageAlt: "Avianca",
      title: "Avianca",
      address:
        "Paseo General Escalón, Centro Comercial Galerías Primer Nivel, local 137A, San Salvador",
      rating: 4.7,
      buttonText: t("Book Now"),
      urlLink: "https://www.avianca.com/",
    },
    {
      category: "Flight",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/delta-one-head-rest.jpg",
      imageAlt: "Delta",
      title: "Delta",
      address: "1030 Delta Blvd, Dept 982, Atlanta, GA, 30354, USA",
      rating: 4.9,
      buttonText: t("Book Now"),
      urlLink: "https://www.delta.com/",
    },
    {
      category: "Flight",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/jetblue.jpg",
      imageAlt: "jetBlue",
      title: "jetBlue",
      address: "27-01 Queens Plz N, Suite 6, Long Island City, NY",
      rating: 4.8,
      buttonText: t("Book Now"),
      urlLink: "https://www.jetblue.com/",
    },
    {
      category: "Flight",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/American Airlines.jpg",
      imageAlt: "American Airlines",
      title: "American Airlines",
      address: "P.O. Box 619616. DFW Airport, TX 75261-9616",
      rating: 4.8,
      buttonText: t("Book Now"),
      urlLink: "https://www.aa.com/",
    },
    {
      category: "Flight",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/united-airlines.jpeg",
      imageAlt: "Unites Airlines",
      title: "Unites Airlines",
      address: "233 South Wacker Drive, Chicago, Illinois",
      rating: 4.5,
      buttonText: t("Book Now"),
      urlLink: "https://www.united.com",
    },
    {
      category: "Flight",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/Spirit-Airlines.jpg",
      imageAlt: "Spirit Airlines",
      title: "Spirit Airlines",
      address: "2800 Executive Way, Miramar, FL",
      rating: 4.0,
      buttonText: t("Book Now"),
      urlLink: "https://www.spirit.com/",
    },
    {
      category: "Activities",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/gal-walking02.jpg",
      imageAlt: "Antigua Cultural Walking Tour",
      title: "Antigua Cultural Walking Tour",
      address:
        "5a Avenida Norte #4 (inside Cafe Condesa) in front of Central Park",
      rating: 4.3,
      buttonText: t("Book Now"),
      urlLink:
        "https://antiguatours.net/weddings/eder-anmol-wedding/",
    },
    {
      category: "Activities",
      imageSrc: "https://dei7w7b9qtqsy.cloudfront.net/images/gall-pacaya04.jpg",
      imageAlt: "Pacaya Volcano Hike",
      title: "Pacaya Volcano Hike",
      address:
        "5a Avenida Norte #4 (inside Cafe Condesa) in front of Central Park",
      rating: 4.3,
      buttonText: t("Book Now"),
      urlLink: "https://antiguatours.net/weddings/eder-anmol-wedding/",
    },
    {
      category: "Activities",
      imageSrc:
        "https://dei7w7b9qtqsy.cloudfront.net/images/gall-atitlan-tour02.jpg",
      imageAlt: "Lake Atitlan Tour",
      title: "Lake Atitlan Tour",
      address:
        "5a Avenida Norte #4 (inside Cafe Condesa) in front of Central Park",
      rating: 4.3,
      buttonText: t("Book Now"),
      urlLink: "https://antiguatours.net/weddings/eder-anmol-wedding/",
    },
    //Add more Cards as needed
    //in these Category: Lodging, Flight, Activites
  ];

  const filteredCards = cards.filter(
    (card) => card.category === selectedButton || selectedButton === "all"
  );

  return (
    <>
      <Container
        id={"travel-and-stay"}
        maxWidth="lg"
        className="text-center"
        style={{ backgroundColor: "#fff", maxWidth: "unset" }}
      >
        <div className="py-12">
          <h5 className="text-[30px] md:text-[40px] lg:text-[48px] font-eb font-bold">
            {t("Travel & Stay")}
          </h5>
          <p className="text-[13px] md:text-[15px] lg:text-[19px] mt-4">
            {t(
              "We recommend booking your stay in Antigua Guatemala as soon as possible. The city is a popular tourist destination and accommodations can fill up quickly. To ensure a comfortable and stress-free experience, we advise arriving a day before the wedding festivities begin. Below are some of our favorite lodging and activities in Antigua."
            )}
          </p>
        </div>
        <div className="text-center pb-12">
          <div className="flex items-center text-sm md:text-lg justify-center flex-wrap gap-4 p-2">
            <button
              style={{
                backgroundColor:
                  selectedButton === "lodging" ? "#586959" : "#999999",
              }}
              onClick={() => toggleCategory("lodging")}
              className={
                isMobile ? tailwindStyle.button2Mobile : tailwindStyle.button2
              }
            >
              <Lodging />
              {t("Lodging")}
            </button>
            <button
              style={{
                backgroundColor:
                  selectedButton === "Flight" ? "#586959" : "#999999",
              }}
              onClick={() => toggleCategory("Flight")}
              className={
                isMobile ? tailwindStyle.button2Mobile : tailwindStyle.button2
              }
            >
              <Flight />
              {t("Flight")}
            </button>
            <button
              style={{
                backgroundColor:
                  selectedButton === "Activities" ? "#586959" : "#999999",
              }}
              onClick={() => toggleCategory("Activities")}
              className={
                isMobile ? tailwindStyle.button2Mobile : tailwindStyle.button2
              }
            >
              <Activities />
              {t("Activities")}
            </button>
          </div>
        </div>
      </Container>

      <Container
        style={{
          backgroundColor: "#fff",
          maxWidth: "unset",
          position: "relative",
        }}
      >
        <Slider ref={slider} {...settings}>
          {filteredCards.map((card, index) => (
            <CustomCard
              key={index}
              imageSrc={card.imageSrc}
              imageAlt={card.imageAlt}
              title={card.title}
              address={card.address}
              rating={card.rating}
              buttonText={card.buttonText}
              urlLink={card.urlLink}
            />
          ))}
        </Slider>

        <button
          onClick={() => slider?.current?.slickPrev()}
          className="absolute top-1/3 left-2 md:left-5 bg-black/70 rounded-full px-4 py-3 hover:opacity-75 transition-colors"
        >
          <ChevronLeft fill="white" />
        </button>
        <button
          onClick={() => slider?.current?.slickNext()}
          className="absolute top-1/3 right-2 md:right-5 bg-black/70 rounded-full px-4 py-3 hover:opacity-75 transition-colors"
        >
          <ChevronRight fill="white" />
        </button>
      </Container>
    </>
  );
};

export default CardContainer;
