import React from "react";

const Car = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M35.2705 19.6602L36.7167 18.455L33.4098 12.6679C33.0537 12.0447 32.391 11.6602 31.6733 11.6602H16.3159C15.5981 11.6602 14.9355 12.0447 14.5794 12.6679L11.2725 18.455L12.7187 19.6602H35.2705Z"
          fill="#BF7F53"
        />
        <path
          d="M7.99512 41.6602C5.78598 41.6602 3.99512 39.8693 3.99512 37.6602H11.9951C11.9951 39.8693 10.2043 41.6602 7.99512 41.6602Z"
          fill="#BF7F53"
        />
        <path
          d="M39.9951 41.6602C37.786 41.6602 35.9951 39.8693 35.9951 37.6602H43.9951C43.9951 39.8693 42.2043 41.6602 39.9951 41.6602Z"
          fill="#BF7F53"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.107 10.6833C12.1753 8.81387 14.1633 7.66016 16.3165 7.66016H31.6739C33.827 7.66016 35.8151 8.81387 36.8833 10.6833L39.8382 15.8543L40.7148 15.1237C41.5634 14.4166 42.8245 14.5312 43.5316 15.3798C44.2387 16.2283 44.1241 17.4895 43.2755 18.1966L41.8537 19.3815L42.6776 20.8233C43.541 22.3343 43.9952 24.0444 43.9952 25.7847V37.6602H3.99517V25.7847C3.99517 24.0444 4.44932 22.3343 5.31274 20.8233L6.13663 19.3815L4.7148 18.1966C3.86624 17.4895 3.7516 16.2283 4.45872 15.3798C5.16585 14.5312 6.42698 14.4166 7.27554 15.1237L8.15218 15.8543L11.107 10.6833ZM36.7173 18.455L35.2711 19.6602H12.7193L11.2731 18.455L14.58 12.6679C14.9361 12.0447 15.5987 11.6602 16.3165 11.6602H31.6739C32.3916 11.6602 33.0543 12.0447 33.4104 12.6679L36.7173 18.455ZM9.99512 28.6602C9.99512 27.0033 11.3383 25.6602 12.9951 25.6602C14.652 25.6602 15.9951 27.0033 15.9951 28.6602C15.9951 30.317 14.652 31.6602 12.9951 31.6602C11.3383 31.6602 9.99512 30.317 9.99512 28.6602ZM31.9951 28.6602C31.9951 27.0033 33.3383 25.6602 34.9951 25.6602C36.652 25.6602 37.9951 27.0033 37.9951 28.6602C37.9951 30.317 36.652 31.6602 34.9951 31.6602C33.3383 31.6602 31.9951 30.317 31.9951 28.6602Z"
        fill="#BF7F53"
      />
    </svg>
  );
};

export default Car;
