import React from "react";

const Line = () => {
  return (
    <svg
      width="2"
      height="57"
      viewBox="0 0 2 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0V57" stroke="#BF7F53" stroke-miterlimit="10" />
    </svg>
  );
};

export default Line;
