import { Box, Paper, Typography } from "@mui/material";

interface CustomPaperProps {
  time: string;
  duration: string;
  title: string;
}

const CustomPaper: React.FC<CustomPaperProps> = ({ time, duration, title }) => {
  const classes = {
    paper: {
      width: "100%",
      maxWidth: "700px",
      height: "100%",
      borderLeft: "5px solid #BF7F53",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "2px",
      marginBottom: "1rem",
      boxShadow: "0"
    },
    topRowContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "0.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    topRightText: {
      color: "#BF7F53",
      fontSize: "14px",
      fontWeight: "regular",
      paddingRight: "1rem"
    },
    topLeftText: {
      paddingLeft:"1rem",
      color: "black",
      fontSize: "14px",
    },
    bottomLeftText: {
      fontSize: "20px",
      fontWeight: "700",
      marginTop: "auto",
    },
  };

  return (
    <Paper sx={classes.paper}>
      <Box style={classes.topRowContainer}>
        <Typography sx={classes.topRightText} variant="subtitle1">
          {time}
        </Typography>
        <Typography sx={classes.topLeftText} variant="subtitle1">
          {duration}
        </Typography>
      </Box>
      <Box style={classes.topRowContainer}>
        <Typography sx={classes.bottomLeftText} variant="body1" className="font-bold">
          {title}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CustomPaper;