import React from "react";

const Shirt2 = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_154_460)">
        <rect
          x="1.5"
          y="1.28418"
          width="24"
          height="24"
          rx="12"
          fill="#586959"
        />
        <g clip-path="url(#clip0_154_460)">
          <path
            d="M17.9325 9.09189C17.8688 9.00938 17.7863 8.94564 17.6888 8.90439L15.36 7.95938C15.2063 7.89939 15.0225 7.89939 14.8763 7.98563C14.7038 8.07188 14.595 8.25562 14.58 8.44688C14.505 8.54814 14.1225 8.75438 13.5 8.75438C12.8775 8.75438 12.495 8.54814 12.42 8.44688C12.4125 8.07188 11.9888 7.80564 11.6475 7.95563L9.31127 8.90439C9.21377 8.94564 9.13126 9.00938 9.06752 9.09189L7.79626 10.8169C7.65376 11.0119 7.65003 11.2781 7.79253 11.4769L9.16877 13.4006C9.35252 13.6669 9.76128 13.7119 9.99751 13.4906L10.2788 18.1256C10.2938 18.4219 10.5413 18.6544 10.8375 18.6544H16.1625C16.4588 18.6544 16.7063 18.4219 16.7213 18.1256L17.0025 13.4906C17.2388 13.7119 17.6475 13.6669 17.8313 13.4006L19.2075 11.4769C19.35 11.2781 19.3463 11.0119 19.2038 10.8169L17.9325 9.09189Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_154_460"
          x="0"
          y="0.53418"
          width="27"
          height="27"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="0.75" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_154_460"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_154_460"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_154_460">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(7.5 7.28418)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Shirt2;
