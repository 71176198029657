import { Box, useMediaQuery } from "@mui/material";
import React, { ReactNode } from "react";

interface SquareProps {
  children: ReactNode;
}

const Square: React.FC<SquareProps> = ({ children }) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const classes = {
    Square: {
      width: isMobile ? "35px" : "65px",
      height: isMobile ? "35px" : "65px",
      backgroundImage: 'url("/image/square.png")',
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      overflow: "hidden",
      margin: 0,
      padding: 0,
      border: 0,
      outline: 0,
    },
  };

  return <Box sx={classes.Square}>{children}</Box>;
};

export default Square;
