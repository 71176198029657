import { Grid, Box, useMediaQuery } from "@mui/material";
import Square from "./Square";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const SquareTimer = () => {
  const [days, setDays] = useState(5);
  const [hours, setHours] = useState(23);
  const [minutes, setMinutes] = useState(59);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  useEffect(() => {
    let interval = setInterval(() => {
      if (minutes === 0 && hours === 0 && days === 0) {
        clearInterval(interval);
      } else {
        if (minutes === 0) {
          setMinutes(59);
          if (hours === 0) {
            setHours(23);
            setDays(days - 1);
          } else {
            setHours(hours - 1);
          }
        } else {
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [days, hours, minutes]);
  const { t } = useTranslation();
  return (
    <Grid
      style={{
        marginLeft: isMobile ? "18%" : "18%",
        gap: isMobile ? "16%" : "2%",
      }}
      container
      justifyContent="center"
      alignItems="center"
      //   spacing={2}
      sx={{ color: "#fffbf6" }}
    >
      <Grid item xs={1.5} alignItems="center">
        <Square>
          <Box
            sx={
              isMobile
                ? { fontSize: "15px", fontWeight: "bold", marginTop: "-2%" }
                : { fontSize: "25px", fontWeight: "bold", marginTop: "-5px" }
            }
          >
            {days < 10 ? `0${days}` : days}
          </Box>
          <Box
            sx={
              isMobile
                ? { fontSize: "7px", fontWeight: "bold", marginTop: "-10%" }
                : { fontSize: "12px", fontWeight: "bold", marginTop: "-5px" }
            }
          >
            {t("Days")}
          </Box>
        </Square>
      </Grid>

      <Grid item xs={1.5}>
        <Square>
          <Box
            sx={
              isMobile
                ? { fontSize: "15px", fontWeight: "bold", marginTop: "-2%" }
                : { fontSize: "25px", fontWeight: "bold", marginTop: "-5px" }
            }
          >
            {hours < 10 ? `0${hours}` : hours}
          </Box>
          <Box
            sx={
              isMobile
                ? { fontSize: "7px", fontWeight: "bold", marginTop: "-10%" }
                : { fontSize: "12px", fontWeight: "bold", marginTop: "-5px" }
            }
          >
            {t("Hours")}
          </Box>
        </Square>
      </Grid>

      <Grid item xs={4}>
        <Square>
          <Box
            sx={
              isMobile
                ? { fontSize: "15px", fontWeight: "bold", marginTop: "-2%" }
                : { fontSize: "25px", fontWeight: "bold", marginTop: "-5px" }
            }
          >
            {minutes < 10 ? `0${minutes}` : minutes}
          </Box>
          <Box
            sx={
              isMobile
                ? { fontSize: "7px", fontWeight: "bold", marginTop: "-10%" }
                : { fontSize: "12px", fontWeight: "bold", marginTop: "-5px" }
            }
          >
            {t("Minutes")}
          </Box>
        </Square>
      </Grid>
    </Grid>
  );
};
export default SquareTimer;
