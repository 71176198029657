import React from "react";

const DressCode = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_80_144)">
        <rect x="2" y="1" width="32" height="32" rx="16" fill="#586959" />
        <g clip-path="url(#clip0_80_144)">
          <path
            d="M23.91 11.41C23.825 11.3 23.715 11.215 23.585 11.16L20.48 9.89998C20.275 9.81999 20.03 9.81999 19.835 9.93499C19.605 10.05 19.46 10.295 19.44 10.55C19.34 10.685 18.83 10.96 18 10.96C17.17 10.96 16.66 10.685 16.56 10.55C16.55 10.05 15.985 9.695 15.53 9.89498L12.415 11.16C12.285 11.215 12.175 11.3 12.09 11.41L10.395 13.71C10.205 13.97 10.2 14.325 10.39 14.59L12.225 17.155C12.47 17.51 13.015 17.57 13.33 17.275L13.705 23.455C13.725 23.85 14.055 24.16 14.45 24.16H21.55C21.945 24.16 22.275 23.85 22.295 23.455L22.67 17.275C22.985 17.57 23.53 17.51 23.775 17.155L25.61 14.59C25.8 14.325 25.795 13.97 25.605 13.71L23.91 11.41Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_80_144"
          x="0"
          y="0"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_144"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_80_144"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_80_144">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(10 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DressCode;
