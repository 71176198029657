import React from "react";

const Activities = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_70_137)">
        <rect x="2" y="1" width="24" height="24" rx="12" fill="white" />
        <path
          d="M14.5817 8.61528C14.4998 8.35158 14.2195 8.20423 13.9558 8.28617C13.6921 8.36812 13.5448 8.64832 13.6267 8.91202C13.7243 9.22593 13.7163 9.45133 13.6643 9.64189C13.6086 9.84616 13.4929 10.0477 13.3107 10.3053C13.1513 10.5307 13.2048 10.8428 13.4303 11.0022C13.6557 11.1616 13.9678 11.1081 14.1272 10.8826C14.3262 10.6012 14.526 10.2832 14.6291 9.90491C14.736 9.5129 14.73 9.09265 14.5817 8.61528Z"
          fill="#586959"
        />
        <path
          d="M17.2874 9.69326C17.316 9.6052 17.3733 9.51604 17.4791 9.44562C17.5863 9.37434 17.7745 9.30188 18.0953 9.30483C18.3714 9.30738 18.5973 9.08559 18.5999 8.80946C18.6024 8.53333 18.3807 8.30742 18.1045 8.30488C17.6277 8.30048 17.2325 8.40867 16.9253 8.61301C16.6168 8.8182 16.4289 9.09964 16.3364 9.38413C16.2907 9.52462 16.2735 9.67717 16.2632 9.79917C16.2585 9.85563 16.253 9.94123 16.2479 10.0221C16.2439 10.0846 16.2401 10.1445 16.2369 10.1857C16.2168 10.4486 16.1817 10.6546 16.1029 10.8086C15.9846 11.04 15.5791 11.2831 14.9132 11.3037C14.6372 11.3122 14.4203 11.5429 14.4289 11.8189C14.4374 12.0949 14.6681 12.3118 14.9441 12.3032C15.7414 12.2786 16.6247 11.9844 16.9932 11.264C17.169 10.9203 17.2126 10.5416 17.234 10.2621C17.2406 10.1755 17.2443 10.1149 17.2475 10.0598C17.2509 10.0025 17.254 9.95122 17.2597 9.88306C17.2691 9.77126 17.2797 9.7168 17.2874 9.69326Z"
          fill="#586959"
        />
        <path
          d="M12.1047 8.47463C11.8286 8.47209 11.6027 8.69387 11.6001 8.97C11.5976 9.24613 11.8194 9.47205 12.0955 9.47459L12.1005 9.47464C12.3766 9.47718 12.6025 9.2554 12.6051 8.97927C12.6076 8.70314 12.3858 8.47723 12.1097 8.47468L12.1047 8.47463Z"
          fill="#586959"
        />
        <path
          d="M18.6047 10.3052C18.3286 10.3027 18.1027 10.5244 18.1001 10.8006C18.0976 11.0767 18.3194 11.3026 18.5955 11.3052L18.6005 11.3052C18.8766 11.3077 19.1025 11.086 19.1051 10.8098C19.1076 10.5337 18.8858 10.3078 18.6097 10.3052L18.6047 10.3052Z"
          fill="#586959"
        />
        <path
          d="M18.1001 12.0547C17.2053 12.0547 16.5783 12.3665 16.0382 12.9246C15.8462 13.1231 15.8514 13.4396 16.0498 13.6316C16.2483 13.8237 16.5648 13.8185 16.7568 13.62C17.1219 13.2428 17.4948 13.0547 18.1001 13.0547C18.3762 13.0547 18.6001 12.8308 18.6001 12.5547C18.6001 12.2786 18.3762 12.0547 18.1001 12.0547Z"
          fill="#586959"
        />
        <path
          d="M18.1047 14.48C17.8286 14.4775 17.6027 14.6992 17.6001 14.9754C17.5976 15.2515 17.8193 15.4774 18.0955 15.48L18.1005 15.48C18.3766 15.4826 18.6025 15.2608 18.6051 14.9847C18.6076 14.7085 18.3859 14.4826 18.1097 14.4801L18.1047 14.48Z"
          fill="#586959"
        />
        <path
          d="M12.0206 11.4423C12.1892 11.4025 12.3665 11.4529 12.489 11.5754L16.0245 15.1109C16.1471 15.2335 16.1974 15.4107 16.1576 15.5794C16.1178 15.748 15.9935 15.884 15.8291 15.9388L14.2666 16.4597L11.1403 13.3333L11.6611 11.7709C11.7159 11.6065 11.8519 11.4822 12.0206 11.4423Z"
          fill="#586959"
        />
        <path
          d="M10.7867 14.394L9.89335 17.0742C9.83346 17.2538 9.88022 17.4519 10.0141 17.5858C10.1481 17.7197 10.3461 17.7665 10.5258 17.7066L13.206 16.8132L10.7867 14.394Z"
          fill="#586959"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_70_137"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_70_137"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_70_137"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Activities;
