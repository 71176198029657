import React from "react";

const Bottom = () => {
  return (
    <svg
      width="156"
      height="26"
      viewBox="0 0 156 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.5 6.99993C86.3 13.4999 78 25.9999 78 25.9999V-7.34488e-05H156C156 -7.34488e-05 134.2 -0.600073 107.5 6.99993ZM48.5 6.99993C69.7 13.4999 78 25.9999 78 25.9999V-7.34488e-05H0C0 -7.34488e-05 21.8 -0.600073 48.5 6.99993Z"
        fill="white"
      />
    </svg>
  );
};

export default Bottom;
