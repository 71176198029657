import { createContext, useState } from "react";

interface ILanguageContext {
  language: string;
  setLanguage: (lang: string) => void;
}
interface LanguageProviderProps {
  children: React.ReactNode;
}
export const LanguageContext = createContext<ILanguageContext | undefined>(
  undefined
);

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState<string>("en");

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
