import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Flight from "./icons/Flight";
import Lodging from "./icons/Lodging";
import Activities from "./icons/Activities";
import { useTravelAndStay } from "../Context/TravelAndStay";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LanguageIcon from "./icons/LanguageIcon";

const HeroSection: React.FC = () => {
  const { toggleCategory } = useTravelAndStay();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleButtonNextSection = () => {
    const nextSection = document.getElementById("data-section");
    if (nextSection) {
      window.scrollTo({
        top: nextSection.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleButtonSectionTravel = (value: string) => {
    toggleCategory(value);
    const nextSection = document.getElementById("travel-and-stay");
    if (nextSection) {
      window.scrollTo({
        top: nextSection.offsetTop,
        behavior: "smooth",
      });
    }
    closeMenu();
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const mobileButtonContainerStyle = {
    position: "absolute",
    top: "5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  };

  const nonMobileButtonContainerStyle = {
    position: "absolute",
    top: 20,
    right: 20,
    display: "flex",
    flexDirection: "row",
  };

  const menuIconStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1000,
    backgroundColor: "black",
    borderRadius: "50%",
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const listItemStyle = {};

  const mobileMenuStyle = {
    position: "absolute",
    top: 70,
    right: 10,
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "10px",
    display: isMenuOpen ? "block" : "none",
    zIndex: 1000,
    width: "auto",
  };

  const styles = {
    background2: {
      position: "relative",
      backgroundImage: `url("https://dei7w7b9qtqsy.cloudfront.net/images/HeroSection.svg")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      filter: "brightness(90%)",
      zIndex: 0,
    },
    buttonGroup: {
      position: "absolute",
      bottom: "20px",
      right: "20px",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      borderRadius: "10px",
      padding: "5px",
    },
    icon: {
      background: "white",
      width: "30px",
      height: "30px",
      color: "gray",
    },
    background: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100%",
      width: "100%",
      display: "grid",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      zIndex: 500,
    },
    textContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      color: "white",
      zIndex: "",
    },
    text: {
      lineHeight: "38px",
      fontFamily: "EB Garamond",
      fontWeight: 700,
    },
    subText: {
      fontFamily: "EB Garamond",
      lineHeight: "32px",
      fontWeight: 500,
    },
    text1: {
      marginBottom: "2rem",
      lineHeight: "55px",
    },
    text2: {
      fontWeight: "100",
      marginBottom: "4rem",
      lineHeight: "55px",
    },
    text3: {
      fontWeight: "100",
      marginBottom: "3rem",
      lineHeight: "55px",
    },
    arrowButtonPosition: {
      position: "absolute",
      bottom: "3rem",
      left: "50%",
      transform: "translateX(-50%)",
    },
    arrowButton: {
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      background: "black",
      animation: "pulsate 2s infinite",
      shadow:
        "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
      "&:hover": {
        background: "#333",
      },
    },
  };

  const tailwindStyle = {
    button1:
      "inline-flex items-center justify-center border border-transparent text-xs font-bold rounded-md text-white bg-darkGray hover:bg-black w-[126px] h-9 mr-2 bg-opacity-90 font-eb",
    button1Moblie:
      "inline-flex items-center justify-center border border-transparent text-sm font-bold rounded-md text-white bg-darkGray hover:bg-black w-[126px] h-9 mr-2 bg-opacity-90 font-eb",
    button2:
      "bg-black hover:bg-black border-none text-white flex items-center justify-center space-x-[12px] font-bold text-lg cursor-pointer px-3 pl-2 pr-5 py-[9px] rounded-full outline-none bg-opacity-40 font-eb",
    button2Mobile:
      "bg-black hover:bg-black border-none text-white flex items-center justify-center space-x-2 font-bold text-sm cursor-pointer px-[9px] pl-2 pr-5 py-1 rounded-full outline-none bg-opacity-40 font-eb",
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    closeMenu();
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Box sx={styles.background2} className="hi">
        <Box
          sx={{
            position: "absolute",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",

            backgroundImage:
              "linear-gradient(to bottom, transparent, black 300%)",
          }}
        />
      </Box>
      {/* TODO Come back and update this*/}
      <Container>
        {isMobile && (
          <IconButton sx={menuIconStyle} onClick={toggleMenu}>
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
        )}

        {isMobile && (
          <Box sx={mobileMenuStyle}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem sx={listItemStyle} disablePadding>
                <ListItemButton
                  onClick={() => changeLanguage("en")}
                  className="gap-3"
                >
                  <ListItemText primary="English" />
                  <LanguageIcon height={30} width={35} />
                </ListItemButton>
              </ListItem>
              <ListItem sx={listItemStyle} disablePadding>
                <ListItemButton
                  onClick={() => changeLanguage("es")}
                  className="gap-3"
                >
                  <ListItemText primary="Español" />
                  <LanguageIcon height={30} width={35} />
                </ListItemButton>
              </ListItem>
              <ListItem sx={listItemStyle} disablePadding>
                <ListItemButton
                  onClick={() => changeLanguage("hi")}
                  className="gap-3"
                >
                  <ListItemText primary="Hindi" />
                  <LanguageIcon height={30} width={35} />
                </ListItemButton>
              </ListItem>
              <ListItem sx={listItemStyle} disablePadding>
                <ListItemButton
                  onClick={() => handleButtonSectionTravel("Flight")}
                >
                  <ListItemText primary="Flight" />
                </ListItemButton>
              </ListItem>
              <ListItem sx={listItemStyle} disablePadding>
                <ListItemButton
                  onClick={() => handleButtonSectionTravel("Lodging")}
                >
                  <ListItemText primary="Lodging" />
                </ListItemButton>
              </ListItem>
              <ListItem sx={listItemStyle} disablePadding>
                <ListItemButton
                  onClick={() => handleButtonSectionTravel("Activities")}
                >
                  <ListItemText primary="Activities" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        )}

        {!isMobile && (
          <Box
            sx={
              isMobile
                ? mobileButtonContainerStyle
                : nonMobileButtonContainerStyle
            }
          >
            <LanguageIcon
              className="rounded-lg mr-2 opacity-90"
              height={36}
              width={50}
            />
            <button
              className={
                isMobile ? tailwindStyle.button1 : tailwindStyle.button1Moblie
              }
              onClick={() => changeLanguage("en")}
            >
              English
            </button>

            <button
              className={
                isMobile ? tailwindStyle.button1 : tailwindStyle.button1Moblie
              }
              onClick={() => changeLanguage("es")}
            >
              Español
            </button>

            <button
              className={
                isMobile ? tailwindStyle.button1 : tailwindStyle.button1Moblie
              }
              onClick={() => changeLanguage("hi")}
            >
              Hindi
            </button>
          </Box>
        )}

        {!isMobile && (
          <Box sx={styles.buttonGroup}>
            <Grid
              sx={isMobile ? { pl: "1.5rem" } : { pl: 0 }}
              container
              spacing={2}
              justifyContent={isMobile ? "center" : "flex-end"}
            >
              <Grid item>
                <button
                  className={
                    isMobile
                      ? tailwindStyle.button2Mobile
                      : tailwindStyle.button2
                  }
                  onClick={() => handleButtonSectionTravel("Flight")}
                >
                  <Flight />
                  <span>{t("Flight")}</span>
                </button>
              </Grid>
              <Grid item>
                <button
                  className={
                    isMobile
                      ? tailwindStyle.button2Mobile
                      : tailwindStyle.button2
                  }
                  onClick={() => handleButtonSectionTravel("lodging")}
                >
                  <Lodging />
                  <span> {t("Lodging")}</span>
                </button>
              </Grid>
              <Grid item>
                <button
                  className={
                    isMobile
                      ? tailwindStyle.button2Mobile
                      : tailwindStyle.button2
                  }
                  onClick={() => handleButtonSectionTravel("Activities")}
                >
                  <Activities />
                  <span>{t("Activities")}</span>
                </button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
      <Box sx={styles.arrowButtonPosition}>
        <IconButton sx={styles.arrowButton} onClick={handleButtonNextSection}>
          <ExpandMoreIcon sx={{ fontSize: "2rem", color: "white" }} />
        </IconButton>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.textContainer}>
          <Typography
            variant="h1"
            fontWeight="reguler"
            sx={styles.text1}
            fontSize={isMobile ? 70 : 100}
          >
            {t("R . S . V . P.")}
          </Typography>
          
          <Typography
            variant="h2"
            sx={styles.text}
            fontSize={isMobile ? 20 : 30}
          >
            {t("Join Us")}
          </Typography>
          <Typography
            variant="h2"
            fontSize={isMobile ? 15 : 24}
            fontWeight={400}
            sx={styles.subText}
          >
            {t("Antigua, Guatemala")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;
