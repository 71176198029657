import * as React from "react";
import Box from "@mui/material/Box";
import { Avatar, IconButton, Typography } from "@mui/material";
import { BiLogoFacebook } from "react-icons/bi";
import { BsInstagram } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Line from "./icons/Line";
import Branch from "./icons/Branch";
import Bottom from "./icons/Bottom";
import Top from "./icons/Top";

interface ColumnProps {
  children: React.ReactNode;
}
interface StoryColumnProps {
  image: string;
  name: string;
  title?: string;
  story: string;
  socialIcons?: boolean;
  color: string;
}

export default function WeddingStory() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "#ecceba",
      }}
      paddingBottom="69px"
      paddingTop="69px"
    >
      <Column>
        <StoryColumn
          image="/image/anmol_headshot.png"
          name="Anmol Kanotra"
          title="Bride"
          story="Anmol, born in Atlanta, Georgia, studied at the University of Louisville and the London School of Economics and is a PhD student in Public Policy at UNC Chapel Hill. She holds a bachelor's in Finance and Economics and a master's in International Health Policy."
          color="black"
          socialIcons={false}
        />
        <div
          style={{
            position: "absolute",
            bottom: "10%",
            left: "88%",
            width: "150px",
          }}
        >
          <img src="/image/leaves_left.png" alt="Leaves" />
        </div>
        <StoryColumn
          image="/image/sahiba_chopra.png"
          name="Sahiba Chopra"
          title="Maid of Honor"
          story="Sahiba Chopra is more than just Anmol's lifelong best friend; she is like a sister, sharing in every significant moment and change in Anmol's life. Their bond is a testament to their inseparable connection and deep friendship. Sahiba holds a Doctor of Law - JD and a Bachelor's in Economics, and works as an Associate at Clifford Chance."
          color="black"
          socialIcons={false}
        />
        <StoryColumn
          image="/image/lilly_ucrain.png"
          name="Lily U-Crain"
          title="Bridesmaid"
          story="Lily is one of Anmol's best friends; she is a beacon of light and laughter in Anmol's life. Since their days as roommates, Lily has been a constant source of support and joy, sharing in countless cherished memories. Her unwavering presence and uplifting spirit have made her an invaluable part of Anmol's journey, brightening every moment with her infectious positivity and warmth."
          color="black"
          socialIcons={false}
        />
        <StoryColumn
          image="/image/sally_simmons.png"
          name="Sally Simmons"
          title="Bridesmaid"
          story="Sally Simmons, one of Anmol's best friends and former roommates, is a driving force of positivity and passion. She is a truly genuine individual, whose sincerity and warmth radiate to those around her. Sally's enthusiasm and dedication are evident in both her personal and professional life. She holds a Master's in Business Administration and is a Senior Account Supervisor at Edelman."
          color="black"
          socialIcons={false}
        />
        <StoryColumn
          image="/image/sara_attarzadeh.png"
          name="Sara Attarzadeh"
          title="Bridesmaid"
          story="Sara Attarzadeh has been a cherished friend to Anmol since their middle school days in Louisville. Known for her warmth and unwavering support, Sara's presence in Anmol's life has been a source of happiness and comfort. Her empathy and kindness make her a true friend. Sara holds a Bachelor's in Business Administration, Computer Information Systems, and Political Science, and works as a Technical Program Manager at DocuSign."
          color="black"
          socialIcons={false}
        />
      </Column>

      <PhotoColumn>
        <div className="absolute -top-6">
          <Top />
        </div>
        <div className="relative flex flex-col items-center">
          <Avatar
            alt="Profile"
            src="/image/our_story.png"
            sx={{ width: 300, height: 300, pt: 3 }}
          />

          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center font-EBGaramond font-bold text-4xl leading-15">
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              {t("our story")}
            </Typography>
          </div>
        </div>

        <StoryColumn
          image="/image/first_meet.png"
          name="First Meet"
          story="Eder and Anmol first crossed paths as next-door neighbors at 1500 Mass Apartments in Washington, DC, just a few blocks from the White House. Their initial encounter in the hallway marked the beginning of a beautiful journey together."
          color="#795e57"
        />
        <Line />
        <StoryColumn
          image="/image/first_trip.png"
          name="First Trip"
          story="Their inaugural adventure took them to Hampton Beach and Portsmouth in New Hampshire, and Kittery in Maine. Since then, Anmol and Eder have cherished their love for travel, exploring the world side by side."
          color="#795e57"
        />
        <Line />
        <StoryColumn
          image="/image/first_love.png"
          name="Shared Passions"
          story="Anmol and Eder have many shared passions, but nothing compares to their endless love of good food. From cooking together to dining out, their love for food has always been a central part of their relationship."
          color="#795e57"
        />
        <Line />
        <StoryColumn
          image="/image/living_together.png"
          name="Living together"
          story="The couple's first home was a cozy 500 sqft apartment in the Upper East Side of Manhattan, New York City. Despite the small space, they created lifelong memories and often reminisce about their time in the bustling city."
          color="#795e57"
        />
        <Line />
        <StoryColumn
          image="/image/proposal.png"
          name="Proposal"
          story="Eder proposed to Anmol in Dumbo, New York, with the iconic Brooklyn Bridge and the East River as their backdrop. The memorable day continued with a photo shoot and a visit to their favorite taco shop, Los Tacos No.1, for some celebratory tacos."
          color="#795e57"
        />
        <div
          style={{
            position: "absolute",
            bottom: "-1.6rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Bottom />
        </div>
      </PhotoColumn>

      <Column>
        <StoryColumn
          image="/image/eder_headshot.png"
          name="Eder Mazariegos"
          title="Groom"
          story="Eder, born in Ocala, Florida, holds a bachelor's degree in Computer Science and Information Technology. He works as a Lead Site Reliability Engineer at Booz Allen Hamilton and is the founder of Blank Cut Inc., a tech startup focused on innovative software solutions."
          color="black"
          socialIcons={false}
        />
        <div
          style={{
            position: "absolute",
            top: "3%",
            right: "88%",
            width: "150px",
          }}
        >
          <img src="/image/leaves_right.png" alt="leaves" />
        </div>

        <StoryColumn
          image="/image/christian_mazariegos.png"
          name="Christian Mazariegos"
          title="Best Man"
          story="Christian is Eder's brother, and their bond is built on mutual love and support. Christian is known for his honesty and exceptional character and is a true embodiment of integrity. He holds a Bachelor's in Information Technology and works at IBM in Washington, DC."
          color="black"
          socialIcons={false}
        />
        <StoryColumn
          image="/image/jay_mccray.png"
          name="Jay McCray"
          title="Groomsman"
          story="Jay is not just Eder's best friend; he's like a brother. Their friendship, spanning over 15 years, began at Vanguard High School in Ocala, FL. Jay's influence in Eder's life is immeasurable, providing guidance and support through every twist and turn. Jay holds a Master's in Executive Leadership and serves as a resource manager at FIS Global."
          color="black"
          socialIcons={false}
        />
        <StoryColumn
          image="/image/steve_palacios.png"
          name="Steve Palacios"
          title="Groomsman"
          story="Steve is a beacon of positivity in Eder's life, known for his infectious charisma and unwavering support. His exceptional character and work ethic shine through in everything he does, making him a cherished friend and confidant. Steve has a Bachelor's in Sports Administration and Entrepreneurship and works as a Business Development Representative at Armis."
          color="black"
          socialIcons={false}
        />
        <StoryColumn
          image="/image/ankur_kanotra.png"
          name="Ankur Kanotra"
          title="Groomsman"
          story="Ankur is Anmol’s brother, but he is also one of Eder’s closest friends. Ankur has tremendous passion for what he does, cares about others dearly, and is a breath of knowledge. Ankur works at North Face in the Global Marketing division. He has a Bachelors in Finance and lives in Washington, DC."
          color="black"
          socialIcons={false}
        />
      </Column>
    </Box>
  );
}

function Column({ children }: ColumnProps) {
  return (
    <Box
      component="div"
      sx={{
        width: "33.33%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "@media (max-width: 767px)": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      {children}
    </Box>
  );
}

function PhotoColumn({ children }: ColumnProps) {
  return (
    <Box
      position="relative"
      component="div"
      sx={{
        position: "relative",
        width: "33.33%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
        borderRadius: "8px",
        backgroundColor: "white",
        zIndex: 1,
        pb: "40px",
        "@media (max-width: 767px)": {
          width: "100%",
          height: "100%",
          pt: "5rem",
          pb: "0",
        },
      }}
    >
      {children}
    </Box>
  );
}

function StoryColumn({
  image,
  name,
  title,
  story,
  socialIcons,
  color,
}: StoryColumnProps) {
  const { t } = useTranslation();
  return (
    <Box
      textAlign="center"
      sx={{
        px: "2rem",
        pt: { xs: "4rem", md: "58px" },
        pb: { xs: "4rem", md: "0" },
      }}
    >
      <Box display="flex" justifyContent="center">
        <Avatar alt="Profile" src={image} sx={{ width: 120, height: 120 }} />
      </Box>
      {title ? (
        <>
          <Typography
            variant="h5"
            fontSize="24px"
            fontWeight={600}
            sx={{ color: "#bf7f53" }}
          >
            {t(title)}
          </Typography>
          <Typography variant="h4" fontSize="32px">
            {t(name)}
          </Typography>
          <div className="flex flex-col justify-center items-center">
            <Branch />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center">
            <Branch />
          </div>
          <Typography variant="h4" fontSize="32px">
            {t(name)}
          </Typography>
        </>
      )}

      <Typography
        variant="h5"
        fontSize="18px"
        paddingTop="10px"
        paragraph
        sx={{ textAlign: "center", px: "1rem", color: color }}
      >
        {t(story)}
      </Typography>
      {socialIcons && (
        <Box display="flex" justifyContent="center" sx={{ gap: "20px" }}>
          <a href="https://www.facebook.com/jaymccray94">
            <IconButton>
              <BiLogoFacebook width="30px" height="30px" color="black" />
            </IconButton>
          </a>

          {/* <IconButton>
            <BsTwitter width="30px" height="30px" color="black" />
          </IconButton> */}

          <a href="https://www.instagram.com/therealjaymccray/">
            <IconButton>
              <BsInstagram width="30px" height="30px" color="black" />
            </IconButton>
          </a>
        </Box>
      )}
    </Box>
  );
}
