import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface CustomCardProps {
  imageSrc: string;
  imageAlt: string;
  title: string;
  address: string;
  rating: number;
  buttonText: string;
  urlLink?: string;
}

const CustomCard: React.FC<CustomCardProps> = ({
  imageSrc,
  imageAlt,
  title,
  address,
  rating,
  buttonText,
  urlLink,
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white max-w-[426px] w-full p-4 shadow-sm rounded-lg mx-auto mb-12 font-eb">
      <div>
        <img className="h-[262px] w-full object-cover" src={imageSrc} alt="" />
        <h2 className="text-2xl font-bold text-darkGray mt-4">{t(title)}</h2>
        <p className="text-darkGray text-lg mb-2 h-[56px] overflow-hidden">
          {t(address)}
        </p>
        <div className="flex justify-between items-end">
          <div className="mb-2 text-lg">
            <span className="text-[#FFA500]">&#9733; </span>
            {rating}
          </div>
          <Link to={`${urlLink}`} target="blank">
            <button className="px-6 py-3 font-bold text-sm rounded-md text-white bg-darkGray hover:bg-orange transition-colors duration-300 focus:outline-none focus:ring-4 focus:ring-gray-300">
              {t(buttonText)}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
