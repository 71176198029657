import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ApiCalls from "../api/Api";
import { toast } from "react-hot-toast";
import Top from "./icons/Top";
import Bottom from "./icons/Bottom";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [dietaryRestrictions, setDietaryRestrictions] = useState<any[]>([]);
  const [selectedDiet, setSelectedDiet] = useState<string>("");
  const [dietaryOptions, setDietaryOptions] = useState<string[]>([
    "Vegetarian",
    "Vegan",
    "Gluten-Free",
    "Non-Dairy",
    "Other",
    "None",
  ]);
  const meatOptions = ["Chicken", "Steak", "Vegetarian/Vegan"];
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [guestName, setGuestName] = useState("");
  const [allergies, setAllergies] = useState("");
  const [meatOption, setMeatOption] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [dietaryRestrictionsError, setDietaryRestrictionsError] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let hasError = false;
  
    if (!name) {
      setNameError("Name is required");
      hasError = true;
    }
    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    }
    if (!phoneNumber) {
      setPhoneError("Phone number is required");
      hasError = true;
    }
    if (!location) {
      setLocationError("Location is required");
      hasError = true;
    }
  
    if (hasError) return;
  
    setLoading(true);
  
    const requestBody = {
      name: name,
      email: email,
      phone_num: phoneNumber,
      location: location,
      dietary_restrictions: dietaryRestrictions,
      guest_name: guestName,
      allergies: allergies,
      meat_option: meatOption,
    };
  
    // Log form data
    ApiCalls.createContactUs(
      requestBody,
      (onData: any) => {
        // Success callback
        toast.success("Thank you for reaching out to us!");
        setLoading(false);
      },
      (onError: any) => {
        // Error callback
        toast.error(onError.response.data.error);
        setLoading(false);
      }
    );
  
    // Reset form
    setName("");
    setEmail("");
    setPhoneNumber("");
    setLocation("");
    setDietaryRestrictions([]);
    setSelectedDiet("");
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setLocationError("");
    setDietaryRestrictionsError("");
    setGuestName("");
    setAllergies("");
    setMeatOption("");
  };  

  function handleChange(event: SelectChangeEvent) {
    const value = event.target.value as string;
    setSelectedDiet(value);
    setDietaryOptions((prev) => prev.filter((option) => option !== value));
  }
  const handleDietaryRemove = (index: number) => {
    const updatedDietary = [...dietaryRestrictions];
    updatedDietary.splice(index, 1);
    setDietaryRestrictions(updatedDietary);
    const removedOption = updatedDietary[index];
    setDietaryOptions((prev) => [...prev, removedOption]);
  };

  useEffect(() => {
    if (selectedDiet) {
      setDietaryRestrictions((prev) => [...prev, selectedDiet]);
    }
  }, [selectedDiet]);

  return (
    <div className="flex flex-col justify-center items-center bg-green py-14 md:py-[200px]">
      <div className="container px-5">
        <div className="flex justify-center items-center max-md:relative max-md:top-1">
          <Top />
        </div>
        <form
          onSubmit={handleSubmit}
          className="bg-white p-7 md:px-[36px] md:pb-[100px] h-auto mx-auto w-full md:w-[512px]"
        >
          <div className="pt-[18px] text-center">
            <h3 className="font-eb font-bold text-[34px] md:text-[40px] lg:text-[48px]">
              {t("Are you attending?")}
            </h3>
            <p className="py-4 text-dark text-base lg:text-lg">
              {t("If you are attending, Kindly respond before May 1st so that we can make the necessary arrangements.")}
            </p>
            <p className="text-lg lg:text-xl">R.S.V.P</p>
          </div>
          <div className="d-grid">
            <div className="d-grid p-1 mt-[29px]">
              <FormControl fullWidth>
                <TextField
                  type="text"
                  id="name"
                  variant="filled"
                  InputProps={{
                    style: {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "white",
                    },
                    disableUnderline: true,
                  }}
                  label={t("Name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <FormHelperText sx={{ color: "#ff0000" }}>
                  {nameError}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="d-grid p-1">
              <FormControl fullWidth>
                <TextField
                  type="text"
                  id="guestName"
                  variant="filled"
                  InputProps={{
                    style: {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "white",
                    },
                    disableUnderline: true,
                  }}
                  label={t("Guest Name")}
                  value={guestName}
                  onChange={(e) => setGuestName(e.target.value)}
                />
              </FormControl>
            </div>
            
            <div className="d-grid p-1">
              <FormControl fullWidth>
                <TextField
                  type="email"
                  id="email"
                  variant="filled"
                  InputProps={{
                    style: {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "white",
                    },
                    disableUnderline: true,
                  }}
                  label={t("Email Address")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormHelperText sx={{ color: "#ff0000" }}>
                  {emailError}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="d-grid p-1">
              <FormControl fullWidth>
                <TextField
                  type="number"
                  label={t("Phone number")}
                  id="phoneNumber"
                  variant="filled"
                  InputProps={{
                    style: {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "white",
                    },
                    disableUnderline: true,
                  }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <FormHelperText sx={{ color: "#ff0000" }}>
                  {phoneError}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="d-grid p-1">
              <FormControl variant="filled" fullWidth>
                <InputLabel id="add-label" htmlFor="dietaryRestrictions">
                  {t("Where are you coming from")}
                </InputLabel>
                <Select
                  id="location"
                  labelId="location-label"
                  label="Where are you coming from?"
                  variant="filled"
                  inputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    background: "white",
                  }}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <MenuItem value="United States">United States</MenuItem>
                  <MenuItem value="Europe">Europe</MenuItem>
                  <MenuItem value="Guatemala">Guatemala</MenuItem>
                  <MenuItem value="India">India</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#ff0000" }}>
                  {locationError}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="d-grid p-1">
              <FormControl variant="filled" fullWidth>
                <InputLabel id="meat-option-label" htmlFor="meatOption">
                  {t("Meat Option")}
                </InputLabel>
                <Select
                  id="meatOption"
                  labelId="meat-option-label"
                  label={t("Meat Option")}
                  variant="filled"
                  inputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    background: "white",
                  }}
                  value={meatOption}
                  onChange={(e) => setMeatOption(e.target.value)}
                >
                  {meatOptions.map((option) => (
                    <MenuItem key={option} value={option} sx={{ fontSize: 16 }}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="d-grid p-1">
              <FormControl fullWidth>
                <TextField
                  type="text"
                  id="allergies"
                  variant="filled"
                  InputProps={{
                    style: {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      background: "white",
                    },
                    disableUnderline: true,
                  }}
                  label={t("Allergies")}
                  value={allergies}
                  onChange={(e) => setAllergies(e.target.value)}
                />
              </FormControl>
            </div>
            <div
              className="d-grid p-4 mx-1 rounded-[10px]"
              style={{ border: "1px solid #586959" }}
            >
              <FormControl variant="filled" fullWidth>
                <InputLabel id="add-label" htmlFor="dietaryRestrictions">
                  {t("Add Dietary restrictions")}
                </InputLabel>
                <Select
                  labelId="add-label"
                  style={{ width: "100%", background: "white" }}
                  label="Where are you coming from?"
                  variant="filled"
                  inputProps={{
                    id: "dietaryRestrictions",
                    disableUnderline: true,
                  }}
                  value={selectedDiet}
                  onChange={handleChange}
                >
                  {dietaryOptions.map((option) => (
                    <MenuItem key={option} value={option} sx={{ fontSize: 16 }}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {dietaryRestrictions.length > 0 && (
                  <List
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    {dietaryRestrictions.map((restriction, index) => (
                      <ListItem
                        key={index}
                        style={{
                          height: "32px",
                          width: "auto",
                          borderRadius: "4px",
                          backgroundColor: "#ecceba",
                          padding: "4px 8px",
                        }}
                      >
                        <Button onClick={() => handleDietaryRemove(index)}>
                          <div className="flex justify-center items-center font-medium text-base font-eb text-dark">
                            <span style={{}}>{restriction}</span>
                            <CloseIcon
                              style={{
                                margin: "0px 0px 0px 10px",
                                fontSize: "16px",
                              }}
                            />
                          </div>
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                )}
                {dietaryRestrictionsError && (
                  <FormHelperText sx={{ color: "#ff0000" }}>
                    {dietaryRestrictionsError}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="p-1 mt-2 pt-5 flex justify-center items-center">
              <button
                className="px-5 py-3 font-eb font-bold text-base md:text-xl bg-green rounded-[100px] text-white hover:opacity-75 transition-colors"
                type="submit"
              >
                {loading ? t("Loading") : t("Submit")}
              </button>
            </div>
          </div>
        </form>
        <div className="flex justify-center items-center max-md:relative max-md:bottom-1">
          <Bottom />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
