import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4040/";
class ApiCalls {
  public baseUrl = `${API_URL}/api`;
  public contactController = `${this.baseUrl}/contact`;
  onError = (error: any) => {
    console.log("an error occurred: ", error);
  };
  createContactUs = (ConstactUs: any, onData: any, onError: any) => {
    axios.post(this.contactController, ConstactUs).then(onData, onError);
  };
}

const apiCalls = new ApiCalls();

export default apiCalls;
