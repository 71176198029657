import React from "react";

const Location = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99984 16.7002C9.9165 13.7002 12.8332 11.0139 12.8332 7.7002C12.8332 4.38649 10.2215 1.7002 6.99984 1.7002C3.77818 1.7002 1.1665 4.38649 1.1665 7.7002C1.1665 11.0139 4.08317 13.7002 6.99984 16.7002Z" stroke="#364050" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.99984 10.0335C8.38055 10.0335 9.49984 8.91424 9.49984 7.53353C9.49984 6.15282 8.38055 5.03353 6.99984 5.03353C5.61913 5.03353 4.49984 6.15282 4.49984 7.53353C4.49984 8.91424 5.61913 10.0335 6.99984 10.0335Z" stroke="#364050" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default Location;
