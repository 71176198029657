


import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { LanguageProvider } from "./hooks/languageContext";
import { I18nextProvider } from "react-i18next";
import i18next from "./hooks/i18nextProvider";
import ToasterProvider from "./provider/ToasterProvider";
import { createClient } from '@supabase/supabase-js';
import { SessionContextProvider } from "@supabase/auth-helpers-react";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;


if (!supabaseUrl || !supabaseKey) {
  throw new Error("Supabase URL and key must be provided in the environment variables.");
}

const supabase = createClient(
  supabaseUrl,
  supabaseKey
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <LanguageProvider>
        <ToasterProvider>
          <ThemeProvider theme={theme}>
            <SessionContextProvider supabaseClient={supabase}>
            <App />
            </SessionContextProvider>
          </ThemeProvider>
        </ToasterProvider>
      </LanguageProvider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
