import React from "react";

const Plane = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_80_216)">
        <path
          d="M42.335 32.6602V28.6602L26.335 18.6602V7.66016C26.335 6.00016 24.995 4.66016 23.335 4.66016C21.675 4.66016 20.335 6.00016 20.335 7.66016V18.6602L4.33496 28.6602V32.6602L20.335 27.6602V38.6602L16.335 41.6602V44.6602L23.335 42.6602L30.335 44.6602V41.6602L26.335 38.6602V27.6602L42.335 32.6602Z"
          fill="#BF7F53"
        />
      </g>
      <defs>
        <clipPath id="clip0_80_216">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.334961 0.660156)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Plane;
