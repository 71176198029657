import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breakfast from "./icons/Breakfast";
import DressCode from "./icons/DressCode";
import Map from "./map/Map";
import TimelineItem from "./TimelineItem";
import MapIcon from "./icons/MapIcon";

export interface TimelineItemProps {
  image: string;
  title: string;
  subheading?: string;
  name: string;
  description: string;
  icons: {
    icon: React.ReactNode;
    label: string;
  }[];
}

export default function Timeline() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const timeline: TimelineItemProps[] = [
    {
      image: "https://dei7w7b9qtqsy.cloudfront.net/images/day-1.png",
      title: t("Day One"),
      subheading: t("Location: Santa Clara Gardens"),
      name: t("Mehndi and Sangeet"),
      description: t("Join us as we kick off"),
      icons: [
        {
          icon: <Breakfast />,
          label: t("Dinner is Provided"),
        },
        {
          icon: <DressCode />,
          label: t("Dress Code Indian or Formal Attire"),
        },
      ],
    },
    {
      image: "https://dei7w7b9qtqsy.cloudfront.net/images/day-2.png",
      title: t("Day Two"),
      subheading: t("Location: Convento Santa Clara & Reception at Casa Santo Domingo"),
      name: t("Main Ceremony"),
      description: t("Main Ceremony and Reception"),
      icons: [
        {
          icon: <Breakfast />,
          label: t("Dinner is Provided"),
        },
        {
          icon: <DressCode />,
          label: t("Dress Code Indian or Formal Attire"),
        },
      ],
    },
    {
      image: "https://dei7w7b9qtqsy.cloudfront.net/images/day-3.png",
      title: t("Optional: Day Three"),
      subheading: t("Location: Lake Atitlan"),
      name: t("Lake Atitlan"),
      description: t("We invite you to join us"),
      icons: [
        {
          icon: <DressCode />,
          label: t("Informal Attire"),
        },
      ],
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="container max-w-[862px] w-full  flex flex-col items-center justify-center pt-12">
        <h2 className="text-4xl md:text-5xl lg:text-[65px] font-bold font-eb mb-[78px]">
          {t("Event Timeline")}
        </h2>
  
        <div className="text-center md:text-left w-full mx-auto flex flex-col gap-12 items-center justify-center">
          {timeline.map((item, index) => (
            <TimelineItem
              key={index}
              image={item.image}
              title={item.title}
              subheading={item.subheading} // Pass subheading here
              name={item.name}
              description={item.description}
              icons={item.icons}
            />
          ))}
        </div>
  
        <div className="text-center mt-[78px] mb-12">
          <button
            onClick={handleOpen}
            className="bg-darkGray hover:bg-black font-bold text-white px-6 py-3 rounded-[4px] flex items-center gap-1"
          >
            <MapIcon height={20} width={30} fill="white" /> {t("View Map")}
          </button>
          {open && <Map open={open} handleClose={handleClose} />}
        </div>
      </div>
    </div>
  );
  
}
