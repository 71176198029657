import React from "react";
import {
  useSession,
  useSupabaseClient,
  useSessionContext,
} from "@supabase/auth-helpers-react";
import axios from "axios";

const Added: React.FC = () => {
  const supabase = useSupabaseClient();
  const session: any = useSession();
  const { isLoading } = useSessionContext();

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  const createCalendarEvent = async () => {
    const testEvent = {
      summary: "Wedding Party",
      description: "Celebrating a friend's Wedding",
      location: "123 Main Street, Cityville",
      start: {
        dateTime: "2023-12-27T18:00:00.000Z",
      },
      end: {
        dateTime: "2023-12-27T22:00:00.000Z",
      },
      reminders: {
        useDefault: false,
        overrides: [
          {
            method: "email",
            minutes: 30,
          },
        ],
      },
    };

    try {
      const response = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
        testEvent,
        {
          headers: {
            Authorization: `Bearer ${session?.provider_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        const alertMessage =
          "Event created successfully. Check Your Google Calendar";
        window.alert(alertMessage);
        // const userClickedOk = window.confirm(alertMessage);
        signOut();
        window.close();
        // if (userClickedOk) {

        // }
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };
  const fetchData = async () => {
    if (session) {
      await createCalendarEvent();
      await signOut();
    }
  };

  fetchData();

  if (isLoading) {
    return null;
  }

  return <div></div>;
};

export default Added;
