/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, useMediaQuery } from "@mui/material";

const ThankYou = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ textAlign: "center", position: "relative", width: "100%" }}
      >
        {!isMobile && (
          <Grid item xs={12}>
            <img
              src="https://dei7w7b9qtqsy.cloudfront.net/images/footer_image.svg"
              alt="Thank You"
              loading="lazy"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
              }}
            />
          </Grid>
        )}

        {isMobile && (
          <>
            <Grid item xs={12}>
              <img
                src="https://dei7w7b9qtqsy.cloudfront.net/images/footer_image_mobile.svg"
                alt="Couples photo"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "auto",
                  height: "auto",
                  paddingTop: "2rem",
                }}
                loading="lazy"
              />
            </Grid>

            <Grid item xs={12}>
              <img
                src="https://dei7w7b9qtqsy.cloudfront.net/images/footer_image_message_mobile.svg"
                alt="Thank You message photo"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "auto",
                  height: "auto",
                }}
                loading="lazy"
              />
            </Grid>
          </>
        )}
        <div
          style={{
            position: "absolute",
            bottom: "5%",
            left: isMobile ? "50%" : "5%",
            transform: isMobile ? "translate(-50%, 6px)" : "translate(0, 6px)",
            textAlign: isMobile ? "center" : "left",
            width: isMobile ? "100%" : "90%",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              color: "#293716",
              fontSize: "1.3vw",
              margin: 0,
            }}
          >
            {t("If you need any information")}{" "}
            <a
              href="mailto:antigua2024@anmolandeder.com"
              className="hover:opacity-70"
            >
              antigua2024@anmolandeder.com
            </a>
          </p>
        </div>
      </Grid>
    </Box>
  );
};

export default ThankYou;
