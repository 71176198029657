import React from "react";

const Flight = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_70_379)">
        <rect x="2" y="1" width="24" height="24" rx="12" fill="white" />
        <g clip-path="url(#clip0_70_379)">
          <g filter="url(#filter1_d_70_379)">
            <path
              d="M19.1001 15V14L15.1001 11.5V8.75C15.1001 8.335 14.7651 8 14.3501 8C13.9351 8 13.6001 8.335 13.6001 8.75V11.5L9.6001 14V15L13.6001 13.75V16.5L12.6001 17.25V18L14.3501 17.5L16.1001 18V17.25L15.1001 16.5V13.75L19.1001 15Z"
              fill="#586959"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_70_379"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_70_379"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_70_379"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_70_379"
          x="5.6001"
          y="8"
          width="17.5"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_70_379"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_70_379"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_70_379">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(8.6001 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Flight;
