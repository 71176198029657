/// <reference types="@types/googlemaps" />
import React, { useState, useEffect } from "react";
import { Box, Button, Modal, useMediaQuery } from "@mui/material";
import DataModal from "./DataModal";
import FirstModal from "./FirstModal";
import ReactDOM from "react-dom";
import Flight from "../icons/Flight";
import Activities from "../icons/Activities";
import Lodging from "../icons/Lodging";
import { useTranslation } from "react-i18next";
import SquareTimer from "./SquareTimer";

interface MarkerData {
  id: number;
  lat: number;
  lng: number;
  title: string;
  description: string;
  imageUrl: string;
  rating: number;
  ratingCount: number;
  attendees: string;
  location: string;
}

interface MapProps {
  open: boolean;
  handleClose: () => void;
}

interface LatLng {
  lat: number;
  lng: number;
}

const Map: React.FC<MapProps> = ({ open, handleClose }) => {
  const [selectedButton, setSelectedButton] = useState("lodging");
  const [data, setIsClick] = useState<any>({});
  const [isInit, setIsInit] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 14.558506440234396,
    lng: -90.72791074143875,
  });
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  // const [coordinates, setCoordinates] = useState<LatLng[]>([]);
  const [routeCoordinates, setRouteCoordinates] = useState<LatLng[]>([]);
  const { t } = useTranslation();

  const [markers, setMarkers] = useState<MarkerData[]>([
    {
      id: 1,
      lat: 14.5835,
      lng: -90.5275,
      title: t("La Aurora International Airport"),
      description: t(
        "Arrive at the heart of our celebration: La Aurora International Airport, Guatemala City's bustling hub and Central America's busiest airport. Your journey to our special day begins here, amidst the vibrant spirit of Guatemala. We can't wait to welcome you!"
      ),
      imageUrl: "/image/La-Aurora-International-Airport.jpg",
      rating: 4.5,
      ratingCount: 1200,
      attendees: t("800,000+ Attendees per year"),
      location: t("9 Avenida 14-75, Zona 13, Guatemala City, Guatemala"),
    },
    {
      id: 2,
      lat: 14.5551,
      lng: -90.7307,
      title: t("Convento Santa Clara"),
      description: t(
        "Step into history for our ceremony at a breathtaking site: the remains of a grand complex from the early 1700s. Its centerpiece, a church adorned with an ornate facade, tells a story of time and elegance. This enchanting setting, rich with history, will be the backdrop as we begin our new chapter together."
      ),
      imageUrl:
        "https://dei7w7b9qtqsy.cloudfront.net/images/convento_santa_clara_min.svg",
      rating: 4.8,
      ratingCount: 120,
      attendees: t("100+ Attendees"),
      location: t("H749+2PW, Antigua Guatemala, Guatemala"),
    },
    {
      id: 3,
      lat: 14.558506440234396,
      lng: -90.72791074143875,
      title: t("Hotel Casa Santo Domingo"),
      description: t(
        "Celebrate with us at the Hotel Museum Spa Casa Santo Domingo in La Antigua Guatemala, a UNESCO World Heritage treasure. Once a majestic 17th-century convent, now transformed into a stunning blend of history and luxury, this venue promises an unforgettable reception, steeped in history and beauty, as we create joyful memories together."
      ),
      imageUrl:
        "https://dei7w7b9qtqsy.cloudfront.net/images/casa_santo_domingo_reception.svg",
      rating: 4.7,
      ratingCount: 5659,
      attendees: t("345 Attendees"),
      location: t("3a Calle Oriente 28 A, Antigua Guatemala, Guatemala"),
    },
    {
      id: 4,
      lat: 14.5567064,
      lng: -90.7343181,
      title: t("Antigua Tours by Elizabeth Bell"),
      description: t(
        "Arrive at the heart of our celebration: La Aurora International Airport, Guatemala City's bustling hub and Central America's busiest airport. Your journey to our special day begins here, amidst the vibrant spirit of Guatemala. We can't wait to welcome you!"
      ),
      imageUrl: "/image/antigua-tours-by-elizabeth-bell.jpg",
      rating: 4.5,
      ratingCount: 354,
      attendees: t("350 Attendees per year"),
      location: t("5a Avenida Norte 4, Antigua Guatemala, Guatemala"),
    },
  ]);

  useEffect(() => {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAdA5cSZkv3e8m9070ZClX1ihtIKBT4j5o&callback=initMap`;
    googleMapScript.async = true;
    googleMapScript.defer = true;
    document.body.appendChild(googleMapScript);
    googleMapScript.onload = initMap;
  }, []);

  const initMap = () => {
    let currentInfoWindow: google.maps.InfoWindow | null = null;
    setTimeout(() => {
      const mapElement = document.getElementById("map");
      if (mapElement) {
        var map = new google.maps.Map(mapElement, {
          center: { lat: currentPosition.lat, lng: currentPosition.lng },
          zoom: 17,
          disableDefaultUI: isMobile ? true : false,
        });

        markers.forEach((marker, index) => {
          const markerPosition = { lat: marker.lat, lng: marker.lng };

          const markerSymbol = {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 20, // Adjust the scale as needed
            fillColor: "orange",
            fillOpacity: 1,
            strokeColor: "white",
            strokeWeight: 2,
            shadow: {
              color: "white",
              opacity: 0.8,
              offset: {
                x: 0,
                y: 0,
              },
              anchor: "center",
            },
          };
          const googleMarker = new google.maps.Marker({
            position: markerPosition,
            map: map,
            title: marker.title,
            icon: markerSymbol,
            label: {
              color: "white",
              fontWeight: "bold",
              fontSize: "14px",
              text: `${index + 1}`,
            },
          });

          let openInfoWindow: any = null;
          const infowindow = new google.maps.InfoWindow({
            content: createCustomPopupContent(marker),
          });

          if (data.foundMarker) {
            const fullView = new google.maps.InfoWindow({
              content: createCustomPopupContent(data.foundMarker),
            });
            if (isInit) {
              if (openInfoWindow) {
                openInfoWindow.close();
              }
              if (currentInfoWindow) currentInfoWindow.close();
              fullView.open(map, googleMarker);
              openInfoWindow = fullView;
              currentInfoWindow = fullView;
            }
          }

          infowindow.close();
          googleMarker.addListener("click", () => {
            if (openInfoWindow) {
              openInfoWindow.close();
            }
            if (currentInfoWindow) currentInfoWindow.close();
            infowindow.open(map, googleMarker);
            openInfoWindow = infowindow;
            currentInfoWindow = infowindow;
          });
          google.maps.event.addListener(map, "click", function (event) {
            infowindow.close();
          });
        });

        const specificPoint = new google.maps.LatLng(
          currentPosition.lat,
          currentPosition.lng
        );
        map.panTo(specificPoint);
        calculateRoute(map);
      }
    }, 1000);
  };

  //to move the map to specific location
  useEffect(() => {
    const findLatLngById = (
      id: number
    ): { lat: number; lng: number } | undefined => {
      const foundMarker = markers.find((marker) => marker.id === id);
      setIsClick({ foundMarker });
      return foundMarker
        ? { lat: foundMarker.lat, lng: foundMarker.lng }
        : undefined;
    };

    let newPosition;
    if (selectedButton === "Flight") {
      newPosition = findLatLngById(4);
      setIsInit(true);
    } else if (selectedButton === "lodging") {
      newPosition = findLatLngById(2);
      setIsInit(true);
    } else if (selectedButton === "Activities") {
      newPosition = findLatLngById(5);
      setIsInit(true);
    }

    if (newPosition) {
      setCurrentPosition(newPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton]);

  useEffect(() => {
    initMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition]);

  const calculateRoute = (map: google.maps.Map) => {
    const directionsService = new google.maps.DirectionsService();

    const waypoints = markers.map((marker) => ({
      location: new google.maps.LatLng(marker.lat, marker.lng),
      stopover: true,
    }));

    const request = {
      origin: waypoints[0].location,
      destination: waypoints[waypoints.length - 1].location,
      waypoints: waypoints.slice(1, waypoints.length - 1),
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK && result) {
        const routeCoordinates = result.routes[0].overview_path.map(
          (point) => ({
            lat: point.lat(),
            lng: point.lng(),
          })
        );
        setRouteCoordinates(routeCoordinates);

        const routePolyline = new google.maps.Polyline({
          path: routeCoordinates,
          geodesic: true,
          strokeColor: "#586959",
          strokeOpacity: 1.0,
          strokeWeight: 3,
        });

        routePolyline.setMap(map);
      }
    });
  };
  const [open1, setOpen1] = useState(false);

  const handleClosed = () => {
    setOpen1(false);
  };
  const handleOpened = () => {
    setOpen1(true);
  };

  const createCustomPopupContent = (marker: MarkerData) => {
    const container = document.createElement("div");

    // Use the Google Maps Geocoding API to retrieve the location name
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(marker.lat, marker.lng);

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results && results[0]) {
          const locationName = results[0].formatted_address; // Location name from geocoding
          console.log(results[0]);
          ReactDOM.render(
            <div>
              <FirstModal
                marker={marker}
                isMobile={isMobile}
                open={open1}
                handleClose={handleClosed}
                locationName={locationName}
                handleOpened={handleOpened}
                directions={`https://www.google.com/maps/dir/?api=1&destination=${marker.lat},${marker.lng}`}
              />
            </div>,
            container
          );
        }
      } else {
        // Handle geocoding error
      }
    });

    return container;
  };

  const styles = {
    buttonGroup2: {
      borderRadius: "10px",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      gap: "1rem",
      fontSize: "15px",
    },
  };
  const tailwindStyle = {
    button2:
      "bg-[#999999] hover:bg-green border-none text-white flex items-center justify-center space-x-1 font-medium text-18 cursor-pointer pl-2 pr-5 py-1.5 rounded-full outline-none bg-opacity-50",
    button2Mobile:
      "bg-[#999999] hover:bg-green border-none text-white flex items-center justify-center space-x-2 font-medium text-sm cursor-pointer px-1.5 py-1 rounded-full outline-none bg-opacity-50",
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ height: "100%", width: "100%" }}>
        <div id="map" style={{ height: "100%", width: "100%" }}></div>
        <div className="absolute bg-transparent md:bg-white z-50 max-md:-bottom-2 md:top-0 right-[26%] rounded-[100px] w-1/2 h-[10%] md:h-[13%]">
          <div
            className="w-full max-w-full h-auto bg-[url(https://dei7w7b9qtqsy.cloudfront.net/images/timer.svg)] bg-cover bg-center rounded-[20px] flex items-center justify-center"
            style={{
              height: "-webkit-fill-available",
            }}
          >
            <SquareTimer />
          </div>
        </div>
        <div className="flex justify-center">
          <div
            style={{
              position: "absolute",
              backgroundColor: "white",
              zIndex: 9999,
              bottom: "7%",
              borderRadius: "20px",
              width: "max-content",
              height: "62px",
            }}
          >
            <div className="text-center pb-6">
              <Box sx={styles.buttonGroup2}>
                <button
                  style={{
                    backgroundColor:
                      selectedButton === "lodging" ? "#586959" : "#999999",
                  }}
                  onClick={() => setSelectedButton("lodging")}
                  className={
                    isMobile
                      ? tailwindStyle.button2Mobile
                      : tailwindStyle.button2
                  }
                >
                  <Lodging />
                  {t("Lodging")}
                </button>
                <button
                  style={{
                    backgroundColor:
                      selectedButton === "Flight" ? "#586959" : "#999999",
                  }}
                  onClick={() => setSelectedButton("Flight")}
                  className={
                    isMobile
                      ? tailwindStyle.button2Mobile
                      : tailwindStyle.button2
                  }
                >
                  <Flight />
                  {t("Flight")}
                </button>
                <button
                  style={{
                    backgroundColor:
                      selectedButton === "Activities" ? "#586959" : "#999999",
                  }}
                  onClick={() => setSelectedButton("Activities")}
                  className={
                    isMobile
                      ? tailwindStyle.button2Mobile
                      : tailwindStyle.button2
                  }
                >
                  <Activities />
                  {t("Activities")}
                </button>
              </Box>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            bottom: "15px",
            left: { xs: "20px", md: "40px" },
            backgroundColor: "#353535",
            color: "white",
            zIndex: 9999,
            "&:hover": {
              backgroundColor: "black",
            },
            px: { md: "30px" },
            py: { md: "10px" },
          }}
          onClick={handleClose}
        >
          {t("Close")}
        </Button>
        <DataModal open={open1} handleClose={handleClosed} />

        <div
          className="max-md:hidden absolute top-1/2 -translate-y-1/2 right-5 z-10 bg-white shadow-lg p-5 rounded-lg flex flex-col gap-5"
          style={{ zIndex: 9999 }}
        >
          <a
            className="bg-black/75 p-3 py-2 rounded-full text-white text-center hover:opacity-70 transition-colors"
            href="https://maps.app.goo.gl/xFB6ckP9NrRQXos6A"
            rel="noreferrer"
            target="_blank"
          >
            {t("Coffee Shops")}
          </a>
          <a
            className="bg-black/75 p-3 py-2 rounded-full text-white text-center hover:opacity-70 transition-colors"
            href="https://maps.app.goo.gl/8zh16cJVxcsPkDS3A"
            rel="noreferrer"
            target="_blank"
          >
            {t("Restaurants")}
          </a>
          <a
            className="bg-black/75 p-3 py-2 rounded-full text-white text-center hover:opacity-70 transition-colors"
            href="https://maps.app.goo.gl/FU7szzxqXvHCHuNc8"
            rel="noreferrer"
            target="_blank"
          >
            {t("Shopping")}
          </a>
        </div>
      </Box>
    </Modal>
  );
};

export default Map;
