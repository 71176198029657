import React from "react";

const Lodging = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_70_14)">
        <rect x="2" y="1" width="24" height="24" rx="12" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1001 8C9.82396 8 9.6001 8.22386 9.6001 8.5C9.6001 8.77614 9.82396 9 10.1001 9H10.6001V17H10.1001C9.82396 17 9.6001 17.2239 9.6001 17.5C9.6001 17.7761 9.82396 18 10.1001 18H13.6001V16L13.1001 16C12.9107 16 12.7376 15.893 12.6529 15.7236C12.5682 15.5542 12.5865 15.3515 12.7001 15.2C13.6001 14 15.6001 14 16.5001 15.2C16.6137 15.3515 16.632 15.5542 16.5473 15.7236C16.4626 15.893 16.2895 16 16.1001 16H15.6001V18H19.1001C19.3762 18 19.6001 17.7761 19.6001 17.5C19.6001 17.2239 19.3762 17 19.1001 17H18.6001V9H19.1001C19.3762 9 19.6001 8.77614 19.6001 8.5C19.6001 8.22386 19.3762 8 19.1001 8H10.1001ZM13.1001 10C12.824 10 12.6001 10.2239 12.6001 10.5V11C12.6001 11.2761 12.824 11.5 13.1001 11.5H13.6001C13.8762 11.5 14.1001 11.2761 14.1001 11V10.5C14.1001 10.2239 13.8762 10 13.6001 10H13.1001ZM15.6001 10C15.324 10 15.1001 10.2239 15.1001 10.5V11C15.1001 11.2761 15.324 11.5 15.6001 11.5H16.1001C16.3762 11.5 16.6001 11.2761 16.6001 11V10.5C16.6001 10.2239 16.3762 10 16.1001 10H15.6001ZM12.6001 12.5C12.6001 12.2239 12.824 12 13.1001 12H13.6001C13.8762 12 14.1001 12.2239 14.1001 12.5V13C14.1001 13.2761 13.8762 13.5 13.6001 13.5H13.1001C12.824 13.5 12.6001 13.2761 12.6001 13V12.5ZM15.6001 12C15.324 12 15.1001 12.2239 15.1001 12.5V13C15.1001 13.2761 15.324 13.5 15.6001 13.5H16.1001C16.3762 13.5 16.6001 13.2761 16.6001 13V12.5C16.6001 12.2239 16.3762 12 16.1001 12H15.6001Z"
          fill="#586959"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_70_14"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_70_14"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_70_14"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Lodging;
